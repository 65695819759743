.modal_box {
  width: 100vw;
  max-width: 828px;
}
.item_request {
  width: 100%;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}
.buttons button + button {
  margin-left: 8px;
}
.input_markup {
  font-weight: 500;
  font-size: 11px;
  line-height: 28px;
  width: 120px;
  height: 28px;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: 8px;
  margin-left: 8px;
  background-color: var(--inputBackground);
  color: var(--inputColor);
  border: var(--inputBorder);
  border-radius: var(--inputRadius);
  outline: 0;
  transition: all 0.2s ease;
}
