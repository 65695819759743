.article {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: rgba(var(--black-rgb), 0.86);
}
.article label {
  font-size: 12px;
  font-weight: 600;
}
.article div {
  width: 100%;
}
.article select {
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(var(--black-rgb), 0.86);
  background: transparent;
  border: var(--borderColor1) 1px solid;
  border-radius: 32px;
  box-shadow: none;
  outline: 0;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23555555' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 12px) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}
.article select:focus,
.article select:focus-within {
  box-shadow: none;
  outline: 0;
}
.error {
  font-size: 12px;
  color: red;
  margin-top: 4px;
}
