.loading {
  position: relative;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  border: 9px solid;
  border-color: var(--primaryLoadingLite);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.loading svg {
  height: 38px;
  width: auto;
  fill: var(--primary);
}
.spinning {
  position: absolute;
  top: -9px;
  left: -9px;
  right: -9px;
  bottom: -9px;
  border-radius: 50%;
  border: 9px solid;
  border-color: transparent;
  border-bottom-color: var(--primary);
  animation: spinnerD3wgkg 2s infinite linear;
}

@keyframes spinnerD3wgkg {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1turn);
  }
  100% {
    transform: rotate(1turn);
  }
}
