.article {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}
.header > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 1.5px;
}
.header h2 {
    font-weight: 700;
}
.header h6 {
    font-weight: 500;
}
.filterBx {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    background: rgba(236, 230, 247, 1);
    padding: 6px 3px;
    border-radius: 12px;
}
.filterBx button {
    font-size: 11px;
    font-weight: 600;
    line-height: normal;
    color: rgba(0, 0, 0, 0.5);
    padding: 7.5px 12px;
    background: transparent;
    border-radius: 10px;
    border: 0;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}
.filterBx button.active {
    color: var(--primary);
    background: rgba(240, 242, 245, 1);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}

.statsBx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px;
}
.statsBx > div {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 18px;
    background: var(--white);
    border-radius: 20px;
    padding: 14.25px 15px;
    min-height: 98px;
}
.statsItemContent {
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.statsBx p {
    font-size: 14px;
    font-weight: 500;
    color: rgba(163, 174, 208, 1);
}
.statsBx h3 {
    font-weight: 700;
    color: rgba(43, 54, 116, 1);
}
.statsBx p small {
    font-size: 12px;
    font-weight: 400;
    color: rgba(163, 174, 208, 1);
}
.statsBx p small span {
    font-weight: 700;
    color: rgba(5, 205, 153, 1);
}
.iconBx {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(250, 245, 255, 1);
}

.tableBx {
    padding: 19px 29px;
    background: rgba(255, 255, 255, 1);
    border: rgba(239, 240, 246, 1) 1px solid;
    border-radius: 10px;
}