.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 40px 0;
}
.header > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.header h2 {
  font-weight: 600;
  line-height: normal;
}
.header h6 {
  font-weight: 400;
  line-height: normal;
}
.header > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.content {
  width: 100%;
  max-width: 704px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.alert {
  padding: 16px;
  font-size: 18px;
  font-weight: 500;
  color: var(--error);
  background: rgba(221, 0, 0, 0.07);
  border: rgba(221, 0, 0, 0.2) 1px solid;
  border-radius: 6px;
}

.stepsBx {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.stepsBx h5 {
  font-weight: 500;
}

.button {
  width: 100%;
  height: auto;
  font-size: var(--buttonFontSize);
  line-height: var(--buttonLineHeight);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  padding: var(--buttonHPadding);
  border:transparent 2px solid;
  border-radius: var(--buttonRadius);
  background-color: var(--white);
  color: var(--black);
  font-weight: 600;
  text-align: left;
}
.button_1 {
  background: #F9F5FF;
}
.button > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}
.button > div:nth-of-type(1) > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.button h4 {
  font-weight: 500;
}
.button h4 b {
  font-weight: 700;
}
.button:disabled {
  border: var(--grey) 2px solid;
  filter: blur(2px);
  cursor: not-allowed;
}

.activeBx {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.activeDot {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #00BA00;
}