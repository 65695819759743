.aside {
  width: var(--asideWidth);
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 500;
  font-size: 13px;
  position: fixed;
  top: var(--headerHeight);
  left: 0;
  bottom: 0;
  background-color: var(--white);
  padding: var(--asideVPadding) var(--asideHPadding);
  z-index: calc(var(--headerZIndex) + 10);
  overflow-y: auto;
}
.aside button + button {
  margin-left: 0;
}
.title {
  font-size: 13.5px;
  font-weight: 500;
  color: rgb(var(--black-rgb));
}

/* aside item css */
.asideitem {
  padding: 0;
}
.itemhead {
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
  padding: 9px 12px;
  border-radius: 10px;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.itemhead > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
}
.itemhead label {
  font-size: 13px;
  font-weight: 600;
  color: inherit;
}
.itembody {
  padding-left: 20px;
}
/* /aside item css */

/* aside item content css */
.content_item:last-child {
  padding-bottom: 18px;
}
.content_item_title {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
  padding-top: 16px;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  color: rgba(var(--black-rgb), 0.86);
}
.content_item_title::before {
  content: '';
  width: 18px;
  height: 1px;
  background: var(--borderColor1);
}
.content_item:not(:last-child) .content_item_title {
  border-left: var(--borderColor1) 1px solid;
}
.content_item:last-child .content_item_title {
  border-left: 0;
}
.content_item:last-child .content_item_title::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 9.5px;
  width: 1px;
  background: var(--borderColor1);
}
.content_item_body {
  padding-top: 16px;
  padding-left: 27px;
}
.content_item:not(:last-child) .content_item_body {
  border-left: var(--borderColor1) 1px solid;
}
.content_item_body label {
  font-size: 12px;
  font-weight: 600;
  color: rgba(var(--black-rgb), 0.6);
}
/* /aside item content css */

.primary,
.secondary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;
  height: 44px;
  padding: 6px 16px;
  border-radius: 10px;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.primary {
  background: var(--primary);
  color: var(--white);
}
.secondary {
  background: var(--grey);
  color: var(--white);
}

.linkCenter {
  display: flex;
  justify-content: center;
}

.dragableList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.articleOutline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border: var(--borderColor1) 2px dashed;
  border-radius: 8px;
}
.dragableTitle {
  font-size: 15px;
  font-weight: 600;
  color: rgb(var(--black-rgb));
}
.articleOutline div {
  display: flex;
  align-items: center;
}
.articleOutline button {
  height: auto;
  line-height: 0;
  border: 0;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
  box-shadow: none;
  cursor: pointer;
}

.advanceModal {
  width: calc(100vw - 40px);
  max-width: 760px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 16px 0;
}
