.article {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 0;
}
.accItem {
  background-color: var(--white);
  border-radius: 8px;
}
.accordion {
  width: 100%;
  padding: 10px 13px;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  text-align: left;
  margin: 0;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.accordion > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.accordion h6 {
  font-size: 14px;
  font-weight: 600;
}
.accordion p {
  font-size: 12px;
  font-weight: 400;
}
.accContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 13px;
}
.accordContentItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: var(--white);
  text-align: left;
  border-radius: 8px;
  padding: 6.5px 10px;
  margin: 0;
  border: rgba(50, 50, 71, 0.08) 1px solid;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.accordContentItem h6 {
  font-size: 12px;
  font-weight: 600;
}
