.article {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}
.header > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.header h2 {
    font-weight: 700;
}
.header h6 {
    font-weight: 500;
}
.enableRw {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background: var(--white);
    padding: 12px 16px;
    border-radius: 8px;
}
.enableRw h6 {
    font-weight: 600;
}
.listBx {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
    background: var(--white);
    padding: 28px 16px 12px;
    border-radius: 8px;
}
.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding-bottom: 16px;
}
.listItem:not(:last-child) {
    border-bottom: var(--borderColor) 1px solid;
}
.listItem label {
    font-size: 14px;
    font-weight: 600;
}
.listItem > div {
    width: 33%;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}