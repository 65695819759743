.header {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
}

.layout1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo picture {
  line-height: 0;
}

.layoutInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.toggleMenu {
  display: none;
}

.mobileView .toggleMenu {
  display: flex;
}
.mobileView .layoutInner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 200px;
  background-color: rgb(255, 255, 255);
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateX(200px);
  transition: all 0.35s ease;
  z-index: -12;
}
.mobileView .menu_active {
  opacity: 1;
  transform: translateX(0);
  z-index: 12;
}
.mobileView .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.76);
  z-index: 10;
}

@media (max-width: 991px) {
  .toggleMenu {
    display: flex;
  }
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.76);
    z-index: 10;
  }
  .layoutInner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 200px;
    background-color: rgb(255, 255, 255);
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateX(200px);
    transition: all 0.35s ease;
    z-index: -12;
  }
  .menu_active {
    opacity: 1;
    transform: translateX(0);
    z-index: 12;
  }
}
