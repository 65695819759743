.modal_box {
  width: 100%;
}
.branch_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.check_item {
  display: flex;
  align-items: center;
}
.checkbox {
  width: 27px;
}
.input_markup {
  width: 140px;
  font-weight: 500;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--inputBackground);
  color: var(--inputColor);
  border: var(--inputBorder);
  border-radius: var(--inputRadius);
  outline: 0;
  transition: all 0.2s ease;
}
