.article {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: rgba(var(--black-rgb), 0.86);
}
.article label {
  font-size: 12px;
  font-weight: 600;
}
.article div {
  width: 100%;
}
.article input {
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  color: rgba(var(--black-rgb), 0.86);
  background: transparent;
  border: var(--borderColor1) 1px solid;
  box-shadow: none;
  outline: 0;
}
.article input::-webkit-input-placeholder {
  color: rgba(var(--black-rgb), 0.24);
  font-weight: 400;
}
.article input::-moz-placeholder {
  color: rgba(var(--black-rgb), 0.24);
  font-weight: 400;
}
.article input:-ms-input-placeholder {
  color: rgba(var(--black-rgb), 0.24);
  font-weight: 400;
}
.article input:-moz-placeholder {
  color: rgba(var(--black-rgb), 0.24);
  font-weight: 400;
}

.article input:focus,
.article input:focus-within {
  box-shadow: none;
  outline: 0;
}
.article input[type='number'] {
  appearance: textfield;
}
.article input[type='number']::-webkit-outer-spin-button,
.article input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.error {
  font-size: 11px;
  color: red;
  margin-top: 4px;
}

.article input[type='checkbox'] {
  display: none;
}
.customCheckbox .checkmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: solid 2px var(--borderColor1);
  background-color: transparent;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 8px;
}
.customCheckbox input[type='checkbox']:checked + .checkmark {
  background-color: var(--primary);
}
