.article {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.article label {
  font-size: 12px;
  font-weight: 600;
  color: rgba(var(--black-rgb), 0.6);
}
.box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 20px;
  border-radius: 16px;
  border: var(--borderColor1) 1px solid;
  color: rgba(var(--black-rgb), 0.86);
}
.box input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  opacity: 0;
  border-radius: 16px;
  z-index: calc(var(--headerZIndex) + 12);
}
.media_info {
  font-size: 11px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 129px;
}
.removeImage {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  padding: 0;
  line-height: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  z-index: calc(var(--headerZIndex) + 24);
}

.recommended {
  font-size: 12px;
  font-weight: 500;
  color: rgba(var(--black-rgb), 0.4);
}
