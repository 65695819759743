.article {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 0;
}
.r1Bx {
  display: flex;
  width: 100%;
  gap: 24px;
}
.r1Bx > div {
  padding: 10px 12.5px;
  background: var(--white);
  border-radius: 10px;
}
.r1Bx > div:nth-of-type(1) {
  width: 16.666%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.r1Bx > div:nth-of-type(2) {
  width: 33.333%;
}
.r1Bx > div:nth-of-type(3) {
  width: 50%;
}
.column1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}
.profileIcon {
  font-size: 22px;
  font-weight: 600;
  color: var(--primary);
  width: 82px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(var(--primary-rgb), 0.16);
  border-radius: 50%;
}
.r1Item1 {
  display: flex;
  align-items: center;
  gap: 8px;
}
.r1Item2 {
  display: flex;
  flex-direction: column;
  gap: 6.5px;
}
.r1Item1 label,
.r1Item2 label {
  font-size: 10px;
  color: #8B8D97;
}
.r1Item1 .value {
  font-size: 12px;
  color: var(--black);
}
.r1Item2 .value {
  font-size: 16px;
  color: var(--black);
}
.itemsFlex1 {
  display: flex;
  gap: 16px;
}
.itemsFlex1 > div {
  flex: 1;
}
.itemIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFCC9129;
  border-radius: 7px;
}
.ingredients {
  width: 100%;
  padding: 15px;
  background: var(--white);
  border-radius: 10px;
}
.slider {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}
.sliderBr {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  gap: 15px;
}
.ingCard {
  position: relative;
  width: 175px;
  padding: 16px 16px 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: #F8F8F8F8;
  border-radius: 10px;
  text-align: center;
}
.ingCard h5 {
  font-size: 16px;
  font-weight: 700;
  color: #4F1F73;
}
.badge {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 10px;
  height: 21px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.badge1 {
  background: #DCC9F0;
  color: #4F1F73;
}
.badge2 {
  background: #C2E8D8;
  color: #21AC71;
}
.ingCardIcon {
  font-size: 28px;
  font-weight: 500;
  color: #5B5B5B;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E8E8E8;
  border-radius: 50%;
}
.quantity {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: var(--primary);
}
.quantity_dot {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #C4C4C4;
}
.stepList {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.stepCard {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  z-index: 1;
}
.stepCard:not(:first-child)::before,
.stepCard:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 6.5px;
  width: 2px;
  background: #4F1F73;
  z-index: -1;
}
.stepCard:not(:first-child)::before {
  top: 0;
  bottom: 50%;
}
.stepCard:not(:last-child)::after {
  top: 50%;
  bottom: -24px;
}
.stepCard > div:nth-of-type(1) {
  width: 15px;
  height: 15px;
  background: var(--primary);
  border: #4F1F73 4px solid;
  border-radius: 50%;
}
.stepCard > div:nth-of-type(2) {
  width: 75px;
  font-size: 16px;
  font-weight: 700;
  color: var(--primary);
  lineHeight: normal;
}
.stepCard > div:nth-of-type(3) {
  flex: 1;
}
.grid {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.grid > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: var(--white);
  border-radius: 10px;
}
.grid > div h4 {
  font-weight: 600;
}
.nutList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 12px;
}
.nutCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  width: calc(50% - 12px);
  font-size: 13px;
}
.nutCard > div:nth-of-type(1) {
  width: 15px;
  height: 15px;
  background: var(--primary);
  border: #4F1F73 4px solid;
  border-radius: 50%;
}
.nutCard > div:nth-of-type(2) {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.nutCard > div:nth-of-type(2) > div:nth-of-type(2) {
  font-weight: 700;
  white-space: nowrap;
}

.recipeDetailFlex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexLayer1 {
  display: flex;
}
.flexLayer1 > div {
  padding-left: 19px;
  padding-right: 19px;
}
.label1 {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  color: var(--tableTh);
  line-height: 16px;
  letter-spacing: 0.06em;
}
.label1 > span {
  margin-right: 4px;
}
.value1 {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: var(--tableTdColor);
  margin-top: 8px;
}
.value1 > span {
  margin-right: 4px;
}
.accordionStep {
  border: 1px solid #edf2f7;
  border-radius: 8px;
}
