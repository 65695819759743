.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px 24px;
}

.header {
  margin-bottom: 40px;
}

.title {
  font-size: 32px;
  font-weight: 700;
  color: #1a202c;
  margin-bottom: 16px;
  letter-spacing: -0.5px;
}

.description {
  font-size: 16px;
  color: #4a5568;
  line-height: 1.6;
  max-width: 600px;
}

.cardContainer {
  display: flex;
  justify-content: center;
}

.card {
  background: white;
  border-radius: 16px;
  padding: 32px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.sliderSection {
  margin-bottom: 32px;
}

.sliderValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  font-size: 16px;
  color: #4a5568;
}

.value {
  font-size: 24px;
  font-weight: 600;
  color: #5a67d8;
  background: #ebf4ff;
  padding: 8px 16px;
  border-radius: 8px;
}

.slider {
  width: 100%;
}

/* Style the slider (you may need to adjust these based on your Slider component) */
.slider :global(.MuiSlider-root) {
  color: #5a67d8;
}

.slider :global(.MuiSlider-rail) {
  height: 8px;
  background-color: #e2e8f0;
}

.slider :global(.MuiSlider-track) {
  height: 8px;
}

.slider :global(.MuiSlider-thumb) {
  width: 24px;
  height: 24px;
  background-color: #5a67d8;
  box-shadow: 0 2px 8px rgba(90, 103, 216, 0.4);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.button {
  min-width: 120px;
  height: 44px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.2s ease;
}

.button:not(:disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(90, 103, 216, 0.3);
}

@media (max-width: 768px) {
  .container {
    padding: 24px 16px;
  }

  .title {
    font-size: 28px;
  }

  .card {
    padding: 24px;
  }
}
