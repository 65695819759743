.bx {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}
.row > div {
  flex: 1;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}
.uploadBx {
  width: 156px;
  height: 156px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  background: rgba(237, 242, 247, 1);
  border-radius: 16px;
  padding: 22px 30px;
  text-align: center;
}
.uploadContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.uploadContent h6 {
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  color: rgba(0, 0, 0, 1);
}
.uploadContent p {
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  color: rgba(0, 0, 0, 1);
  margin-top: 4px;
}
