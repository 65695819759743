.border_bottom {
  border-bottom: var(--borderColor) 1px solid;
}
.border_right {
  border-right: var(--borderColor) 1px solid;
}
.h4_2_1 {
  padding: 9px 9px 24px 24px;
}
.content_2_1 {
  padding: 0 9px 24px 24px;
}
.h4_2_2 {
  padding: 9px 24px 24px 9px;
}
.content_2_2 {
  padding: 0 24px 24px 9px;
}
.date_picker {
  height: var(--buttonHeight);
  width: 100%;
  max-width: 324px;
  padding: 11.5px 17px;
  border-radius: var(--buttonRadius);
}
.item_3 {
  padding: 10px 16px;
  margin-top: 16px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 3px 8px -1px rgb(50 50 71 / 5%);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.item_3 img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 8px;
}
.calendar {
  border: 1px solid black;
}
.weekdays {
  border: 1px solid black;
}
.calendar {
  border-width: 0;
  margin-left: 5px;
}
