.page {
  /* padding: 40px 0; */
}
.infoContainer {
  display: flex;
  align-items: center;
}
.infoText {
  font-size: small;
  font-weight: bold;
}

.body {
  margin: 24px 0px 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.instructionsContainer {
  margin-left: 20px;
  list-style-type: circle;
}

.instructionsContainer > li {
  font-size: small;
  font-weight: 400;
  display: flex;
  margin-bottom: 10px;
}

.instructionsContainer > li > a {
  display: flex;
  align-items: center;
  margin: 0px 5px 0px 5px;
}

.cardList {
  display: flex;
  gap: 16px;
}
.cardBx {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: rgb(var(--background-start-rgb));
  border-radius: 16px;
  padding: 24px 20px;

  .header {
    display: flex;
    align-items: center;
    gap: 16px;

    .icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;
      color: rgb(var(--background-start-rgb));
      background: var(--primary);
      border-radius: 40px;
    }

    .div {
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;

      .content {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .p {
          font-size: 11px;
          font-weight: 500;
          color: #b9b9b9;
          margin: 0;
        }

        .h5 {
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          margin: 0;
        }

        .h6 {
          font-size: 14px;
          font-weight: 400;
          color: rgb(var(--grey-rgb));
          margin: 0;
        }
      }

      .badge {
        font-size: 12px;
        font-weight: 600;
        color: rgb(var(--background-start-rgb));
        padding: 4px 8px;
        background: var(--primary);
        border-radius: 24px;
      }
    }
  }

  .cardBody {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 24px;

    .p {
      font-size: 12px;
      font-weight: 500;
      color: rgb(var(--grey-rgb));
      margin: 0;
    }

    .h6 {
      font-size: 14px;
      font-weight: 500;
      color: rgb(var(--foreground-rgb));
      margin: 0;
    }

    .textRight {
      text-align: right;
    }
  }
}
