.header {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: var(--headerZIndex);
}
.hflex {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 430px;
  margin: 0 auto;
}
.logo a {
  display: flex;
  line-height: 0;
}
.logo svg {
  width: 140px;
  height: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
}
.box {
  position: relative;
  width: calc(50% + 5px);
  padding: calc(var(--gap32) / 2);
  background-color: #edf2f7;
  background-image: url(./../../assets/Images/auth_layout_left_top_img.png),
    url(./../../assets/Images/auth_layout_left_bottom_img.png);
  background-position: left top, left bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.content {
  width: 100%;
  max-width: 430px;
  min-height: calc(100vh - 110px);
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.imgbox {
  width: calc(50% - 5px);
  min-height: 100vh;
  background-image: url(./../../assets/Images/auth_layout_right_img.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 767px) {
  .flex {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .box,
  .imgbox {
    width: 100%;
    min-height: initial;
  }
  .imgbox {
    padding-top: 56.6%;
  }
}
