.signin h1 {
  max-width: 435px;
}
.signin h5 {
  max-width: 550px;
}
.max_width_464 {
  width: 100%;
  max-width: 464px;
}
.height_107 {
  height: 107px;
}
.select_overlap_box {
  position: absolute;
  top: 50%;
  right: var(--inputHPadding);
  display: flex;
  align-items: center;
  transform: translateY(-50%);
}
.select_overlap {
  font-size: 12px;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  color: var(--dark);
  -webkit-appearance: none;
  appearance: none;
}
.select_arrow {
  margin-left: 8px;
  margin-bottom: -4px;
}

.box1 {
  width: 100vw;
  max-width: 494px;
  margin: 0 auto;
}
.imgplace {
  display: flex;
  background-color: var(--grey);
}
.imgplace svg {
  width: 100%;
  height: 100%;
}
.imgplace img {
  width: 100%;
  height: auto;
}

.bdd1 {
  background-color: var(--white);
  padding: 14px 12px;
  border-radius: 8px;
  box-shadow: 0px 10px 16px rgba(20, 37, 63, 0.06);
  border: var(--borderColor) 1px solid;
}
.bdd_item {
  font-weight: 500;
  font-size: 14px;
  color: var(--dark);
  text-align: left;
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  border-radius: 6px;
  padding: 10px 11px;
  margin: 0;
}
.bdd_item + .bdd_item {
  margin-top: 6px;
}
.bdd_item:hover {
  background-color: var(--primary);
  color: var(--white);
}
.width_216 {
  width: 216px;
}

.brand_profile {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}
.brand_profile img {
  width: 100%;
  height: auto;
  min-height: 100%;
}
.brand_profile .imgplace svg path {
  stroke-width: 10px;
}

.table th {
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--bgTableTh);
  color: var(--tableTh);
  padding: 18px 16px;
  border-bottom: var(--borderColor) 1px solid;
}
.table thead tr:first-child th:first-child {
  border-radius: 10px 0 0 0;
}
.table thead tr:first-child th:last-child {
  border-radius: 0 10px 0 0;
}
.table td {
  font-weight: 500;
  background-color: var(--white);
  color: var(--dark);
  padding: 8px 16px;
  vertical-align: middle;
  border-bottom: var(--borderColor) 1px solid;
}
.table tbody tr:last-child td {
  border-bottom: 0;
}
.table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}
.table tbody tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}

.shadow_box1 {
  background-color: var(--white);
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.shadow_box {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.shadow_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
  border-bottom: 1px solid #edf2f7;
}
.shadow_body {
  display: flex;
}
.shadow_body_left {
  width: 340px;
  padding: 24px 20px 24px 24px;
  border-right: 1px solid #edf2f7;
}
.shadow_body_right {
  width: calc(100% - 340px);
  padding: 24px;
}
.fixfield_right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fixfield_right > p {
  width: calc(100% - 113px);
}
.fixfield_right > article {
  width: 105px;
  max-width: 105px;
}
.fixfield_right input {
  text-align: center;
}
.label_left label {
  font-weight: 500;
  font-size: 14px;
  color: var(--lableColor);
}
.ingred_item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px 17px;
}
.ingred_item .alink {
  margin-left: 16px;
}
.ingred_item .alink svg {
  width: 15px;
  height: auto;
  fill: var(--error);
  margin: 7.5px;
}
.ingred_item .alink path {
  fill: var(--error);
}
.ingred_item + .ingred_item {
  margin-top: 8px;
}
.ingred_field {
  max-width: 141px;
}
.ingred_field + .ingred_field {
  margin-left: 16px;
}
.modifi_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
}
.modifi_item .alink svg {
  width: 18px;
  height: auto;
  margin: 4px;
}
.w_144 {
  width: 144px;
}
.w_368 {
  width: 368px;
}
.center_auto {
  margin-left: auto;
  margin-right: auto;
}
.add_new_grid {
  display: flex;
  margin: -15.5px;
}
.add_new_grid > * {
  padding: 15.5px;
  width: 100%;
  max-width: 387px;
}
.add_new_button {
  width: 100%;
  min-height: 302px;
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.add_new_button:focus {
  border: 1px solid var(--primary);
}
.add_new_button svg {
  display: inline-block;
}
.add_new_button h4 {
  margin-top: 39px;
  margin-bottom: 11px;
  margin-right: auto;
  margin-left: auto;
}
.add_new_button p {
  font-size: 14px;
  line-height: 17px;
  color: rgba(40, 39, 41, 0.6);
  max-width: 190px;
  margin-right: auto;
  margin-left: auto;
}

.bdd_button {
  font-weight: 500;
  font-size: 14px;
  color: var(--dark);
  text-align: left;
  width: 100%;
  background-color: white;
  border: 0;
  outline: 0;
  box-shadow: none;
  border-radius: 6px;
  padding: 10px 11px;
  margin: 0;
}
.otp_emoji img {
  width: 36px;
  height: auto;
}

.item_ingredient {
  position: relative;
  width: 215px;
  background-color: var(--white);
  padding: 12px;
  margin: 12px;
  border: #edf2f7 1px solid;
  border-radius: 10px;
}
.button_ingredient {
  position: absolute;
  top: 0;
  right: 0;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  transform: translate(50%, -50%);
  border: 0;
  outline: 0;
  border-radius: 50%;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.button_ingredient svg {
  width: 8px;
  height: auto;
  fill: var(--danger);
}

.modal_box1 {
  width: 100vw;
  max-width: 303px;
}
.modal_box2 {
  width: 100vw;
  max-width: 576px;
}
.svg_w10 svg {
  width: 10px;
  height: auto;
}

.min_height_171 {
  min-height: 171px;
}

@media (max-width: 767px) {
  .shadow_box1 {
    padding: 19px;
  }
}
