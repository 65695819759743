.article {
}
.block {
  min-width: 24%;
  height: 280px;
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
  flex: 0 0 20%;
  height: auto;
}
.head {
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 50px;
}
.row {
  display: flex;
  justify-content: space-between;
  border: 0.8px solid #edf2f7;
  align-items: center;
  border-radius: 5px;
  height: 50px;
}
.row_name {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.row_img {
  height: 30px;
  width: 30px;
  border-radius: 5px;
}
.stock {
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-right: 15px;
}
.stock_txt {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #27272e;
}
.stock_t {
  font-weight: 600;
  font-size: 10px;
  color: #718096;
}
.serve_r {
  display: flex;
  border: 0.8px solid #edf2f7;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
}
.btn_con {
  display: flex;
  border: 1px solid #edf2f7;
  border-radius: 25px;
  padding: 0px;
  height: 36px;
  align-items: center;
  /* width: 200px; */
}
.btn {
  width: 105px;
  height: 35px;
  border-radius: 25px;
}
