.fieldbox {
  width: 100%;
}
.field {
  display: flex;
  align-items: center;
}
.label1 {
  font-weight: 500;
  font-size: 14px;
  color: var(--lableColor);
}
.label {
  position: relative;
  margin-right: 8px;
}
/* .required {
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translate(-100%, -50%);
  color: var(--error);
} */
.input {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  opacity: 0;
}
.checks {
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
  border-radius: 4px;
  background-color: #c4c4c4;
  color: var(--white);
  border: 0;
  transition: all ease 0.3s;
  height: 28px;
  line-height: 28px;
  padding-right: 11px;
  padding-left: 11px;
  pointer-events: none;
}
.input:checked + .checks {
  background-color: var(--primary);
}
.infobox {
  position: relative;
  padding: 2px;
  z-index: 1;
}
.infoChild {
  position: absolute;
  top: 0;
  left: 40px;
  display: flex;
  opacity: 0;
  visibility: hidden;
  z-index: -10;
}
.infoChild svg {
  margin: 4px 4px 0 0;
}
.infoContent {
  width: 264px;
  background-color: var(--dark);
  color: var(--white);
  padding: 16px;
  border-radius: var(--bgRadius);
}
.infobox:hover .infoChild {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}
