.emptyCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}
.emptyCard h2 {
    font-weight: 700;
}
.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}
.grid > div,
.grid > button {
    width: calc(25% - 18.75px);
    max-width: 240px;
}
.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: var(--white);
    border-radius: 12px;
}
.card > div {
    width: 100%;
}
.card > div:nth-of-type(1) {
    padding: 24px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex: 1;
}
.card > div:nth-of-type(1).hvCenter {
    justify-content: center;
}
.card > div:nth-of-type(1) > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
.card > div:nth-of-type(1) > div p {
    font-weight: 500;
    color: var(--primary);
}
.cardIcon {
    font-size: 22px;
    font-weight: 600;
    color: var(--primary);
    width: 126px;
    height: 126px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(var(--primary-rgb), 0.16);
    border-radius: 50%;
}
.cardPlusIcon {
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(var(--primary-rgb), 0.16);
    border-radius: 50%;
}
.card > div:nth-of-type(2) button,
.card > div:nth-of-type(2) .asButton {
    width: 100%;
    height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #4F1F73;
    background: rgba(var(--primary-rgb), 0.24);
    border-radius: 0 0 12px 12px;
}