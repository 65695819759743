.article {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}
.header > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 1.5px;
}
.header h2 {
    font-weight: 700;
}
.header h6 {
    font-weight: 500;
}
.filterBx {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    background: rgba(236, 230, 247, 1);
    padding: 6px 3px;
    border-radius: 12px;
}
.filterBx button {
    font-size: 11px;
    font-weight: 600;
    line-height: normal;
    color: rgba(0, 0, 0, 0.5);
    padding: 7.5px 12px;
    background: transparent;
    border-radius: 10px;
    border: 0;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}
.filterBx button.active {
    color: var(--primary);
    background: rgba(240, 242, 245, 1);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}

.tableBx {
    padding: 19px 29px;
    background: rgba(255, 255, 255, 1);
    border: rgba(239, 240, 246, 1) 1px solid;
    border-radius: 10px;
}

.noData {
    padding: 14px 12px;
    font-size: 13px;
    font-weight: 500;
    color: var(--grey);
    text-align: center;
}

.rowExpandBx {
    display: flex;
    gap: 10px;
}
.expandItemBx {
    font-size: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    background: rgba(239, 240, 246, 1);
    border: rgba(239, 240, 246, 1) 1px solid;
    border-radius: 10px;
}
.expandItemBx h6 {
    font-size: 13px;
}
.expandItemBx > h6 {
    font-weight: 600;
}
.item {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}
.item > div {
    flex: 1;
    display: flex;
    gap: 8px;
}
.item h6 {
    font-weight: 500;
}
.item > div img {
    width: 20px;
    height: 20px;
    object-fit: cover;
}
