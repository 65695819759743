.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    background: white;
    outline: none;
    border-radius: 6px;
    box-shadow: 0px 103px 41px 0px rgba(0, 0, 0, 0.01),
      0px 162px 45px 0px rgba(0, 0, 0, 0);
    border: rgba(165, 97, 216, 0.1) 1px solid;
}
.card:hover {
    box-shadow: 0px 103px 41px 0px rgba(0, 0, 0, 0.01),
      0px 162px 45px 0px rgba(0, 0, 0, 0),
      0px 0px 34px 0px rgba(165, 97, 216, 0.2) inset;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.status {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgb(var(--grey-rgb));
}
.badge {
    font-size: 10px;
    font-weight: 600;
    color: rgb(var(--white-rgb));
    background: rgb(var(--grey-rgb));
    padding: 2px 8px;
    border-radius: 24px;
    display: flex;
}
.serial {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgb(var(--grey-rgb));
}
.serial > div {
    color: rgb(var(--foreground-rgb));
}
.modal {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgb(var(--grey-rgb));
}
.modal > span {
    color: rgb(var(--foreground-rgb));
}
.card h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
}
.card p {
    font-weight: 500;
    font-size: 13px;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
}
.footer {
    width: 100%;
    display: flex;
    gap: 10px;
}
.innerButton {
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    letter-spacing: normal;
    color: var(--primary);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    gap: 10px;
    border-radius: 6px;
    background: #f6effb;
    border: 0;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}
.cloudButton {
    background: #faf7fd;
}
  