.page {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px 0;
}
.header {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}
.backRw {
    display: flex;
    align-items: center;
    gap: 16px;
}
.button {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 100%;
    line-height: 0;
    outline: none;
    box-shadow: 0 0 8px rgba(var(--foreground-rgb), 0.16);
    background: rgb(var(--background-end-rgb));
    cursor: pointer;
}
.status {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgb(var(--grey-rgb));
    margin-bottom: 8px;
}
.badge {
    font-size: 12px;
    font-weight: 600;
    color: rgb(var(--white-rgb));
    background: rgb(var(--grey-rgb));
    padding: 2px 8px;
    border-radius: 24px;
    display: flex;
}
.deviceRw {
    display: flex;
    gap: 40px;
}
.deviceRw > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.deviceRw p {
    margin: 0;
    color: rgb(var(--grey-rgb));
}
.deviceRw h6 {
    margin: 0;
    color: rgb(var(--foreground-rgb));
}
.formBx {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.formRw {
    display: flex;
    gap: 24px;
}
.formRw > div {
    flex: 1;
}
.formList {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.toggleRw {
    display: flex;
    align-items: center;
    gap: 8px;
}
.footer {
    display: flex;
    align-items: center;
    gap: 10px;
}