.previewBox {
  font-family: var(--body-font-family);
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
}
.previewBox h1,
.previewBox h2,
.previewBox h3,
.previewBox h4,
.previewBox h5,
.previewBox h6 {
  font-family: var(--header-font-family);
}
.previewBox button {
  font-family: var(--button-font-family);
}
.previewBox button + button {
  margin-left: 0;
}
