.container {
  width: 80%;
  height: auto;
  border: 1px solid black;
}

.head {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.btn_div {
  display: flex;
  float: right;
}
