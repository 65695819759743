.box {
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.box picture {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #edf2f7 1px solid;
  border-radius: 10px;
  line-height: 0;
  overflow: hidden;
}
.box.active picture {
  border: var(--primary) 3px solid;
}
.box picture img {
  width: 100%;
  height: auto;
  min-height: 100%;
}
.box button {
  position: absolute;
  top: 0;
  right: 0;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border: #edf2f7 1px solid;
  border-radius: 10px;
  transform: translate(50%, -50%);
}
.box h6 {
  font-weight: 600;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  background-color: var(--white);
  border: #edf2f7 1px solid;
  border-radius: 14px;
  transform: translateY(60%);
  overflow: hidden;
  text-overflow: ellipsis;
}
.box.active h6 {
  background-color: var(--primary);
  border: var(--primary) 1px solid;
  color: var(--white);
}
