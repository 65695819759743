.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.tab {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  padding: 6px 12px;
  border: 0;
  margin: 0;
  outline: 0;
  box-shadow: none;
}
