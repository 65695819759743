.fieldbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.field {
  position: relative;
  width: calc(100% - 34px);
  display: flex;
  flex-wrap: wrap;
}
.radioItem {
  margin-right: 30px;
  display: flex;
}
.input {
  position: absolute;
  width: 27px;
  height: 19px;
  padding: 0;
  background-color: transparent;
  color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  opacity: 0;
}
.checks {
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--white);
  border: var(--inputBorder);
  margin-right: 8px;
  transition: all ease 0.3s;
  pointer-events: none;
}
.label {
  max-width: calc(100% - 27px);
  font-size: 14px;
  color: inherit;
}
.input:checked + .checks {
  border-color: var(--primary);
  background-color: var(--primary);
}
.input:checked + .checks::before {
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--white);
}
.infobox {
  position: relative;
  padding: 2px;
  z-index: 1;
}
.infoChild {
  position: absolute;
  top: 0;
  left: 40px;
  display: flex;
  opacity: 0;
  visibility: hidden;
  z-index: -10;
}
.infoChild svg {
  margin: 4px 4px 0 0;
}
.infoContent {
  width: 264px;
  background-color: var(--dark);
  color: var(--white);
  padding: 16px;
  border-radius: var(--bgRadius);
}
.infobox:hover .infoChild {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}
