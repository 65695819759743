.fieldbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.field {
  position: relative;
  width: calc(100% - 34px);
  min-width: 27px;
  display: flex;
}
/* .required {
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translate(-100%, -50%);
  color: var(--error);
} */
.input {
  position: absolute;
  width: 27px;
  height: 19px;
  padding: 0;
  background-color: transparent;
  color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  opacity: 0;
}
.checks {
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--white);
  border: var(--inputBorder);
  margin-right: 8px;
  transition: all ease 0.3s;
  pointer-events: none;
}
.label {
  max-width: calc(100% - 27px);
  font-size: 14px;
  color: inherit;
}
.input:checked + .checks {
  border-color: var(--primary);
  background-color: var(--primary);
}
.input:checked + .checks::before {
  content: '';
  width: 7px;
  height: 15px;
  border-right: var(--white) 2px solid;
  border-bottom: var(--white) 2px solid;
  transform: rotate(45deg) translateY(-1px);
}
.infobox {
  position: relative;
  padding: 2px;
  z-index: 1;
}
.infoChild {
  position: absolute;
  top: 0;
  left: 40px;
  display: flex;
  opacity: 0;
  visibility: hidden;
  z-index: -10;
}
.infoChild svg {
  margin: 4px 4px 0 0;
}
.infoContent {
  width: 264px;
  background-color: var(--dark);
  color: var(--white);
  padding: 16px;
  border-radius: var(--bgRadius);
}
.infobox:hover .infoChild {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

.colorRadio {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.colorInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: none;
  background-color: transparent;
  cursor: pointer;
}
.colorRadio > label {
  width: 100%;
  height: 100%;
  display: flex;
  color: transparent;
  border: transparent 3px solid;
  border-radius: 2px;
  opacity: 0.5;
}
.colorInput:checked + label {
  border: var(--white) 3px solid;
  box-shadow: 1px 0px 0px var(--primary), -1px 0px 0px var(--primary),
    0px 1px 0px var(--primary), 0px -1px 0px var(--primary);
  opacity: 1;
}

.menuStatus {
  width: 206px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  background-color: #f6f7f9;
  border-radius: 28px;
}
.deactivate,
.activate {
  position: relative;
}
.deactivate label,
.activate label {
  width: 100px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  line-height: 20px;
  border-radius: 28px;
  background-color: transparent;
  color: #8492a6;
}
.statusInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: none;
  background-color: transparent;
  cursor: pointer;
}
.statusInput:checked + label {
  background-color: var(--titary);
  color: var(--white);
}

.bdLabel {
  width: 70px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: var(--white);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #b789da;
  border-radius: 4px;
  z-index: 0;
}
.bdActive {
  background-color: var(--titary);
  border-radius: 4px;
  z-index: -1;
}
.bdActive,
.bdInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.bdInput {
  width: 100%;
  height: 100%;
  background: none;
  background-color: transparent;
}
.bdInput:checked + .bdActive {
  opacity: 1;
}
