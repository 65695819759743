button {
  font-size: inherit;
  font-weight: 400;
  color: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-shadow: none;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  background-image: none;
  cursor: pointer;
}
.button {
  font-size: var(--buttonFontSize);
  line-height: var(--buttonLineHeight);
  height: var(--buttonHeight);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: var(--buttonHPadding);
  padding-left: var(--buttonHPadding);
}
.button,
.button_sm {
  font-weight: 600;
}
.button_sm {
  font-size: 11px;
  line-height: 1;
  height: 28px;
  padding-right: 8px;
  padding-left: 8px;
}
.button_radius {
  border-radius: var(--buttonRadius);
}
.alink {
  background-color: transparent;
}
.imglink {
  background-color: transparent;
  line-height: 0;
}
.ikonlink {
  background-color: transparent;
  line-height: 0;
  display: flex;
  align-items: center;
}
.ikonlink span + span {
  margin-left: 11px;
}
.add_button {
  font-size: 12px;
  color: #7a7a9d;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  border: var(--borderColor) 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add_button span + span {
  margin-top: 4px;
}
.add_button svg {
  width: 16px;
  height: auto;
}
.add_button.full {
  width: 100%;
  height: 92px;
  color: var(--primary);
}
.add_button.full span + span {
  margin-top: 14px;
}
.optionbutton {
  width: 100%;
  padding: 22px 16px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: var(--lite) 1px solid;
  border-radius: 10px;
}
.optionbutton p {
  font-size: 13px;
  margin-top: 4px;
}
.back {
  background: none;
  line-height: 0;
}
.add_another {
  border: 0;
  outline: 0;
  box-shadow: none;
  background: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: var(--dark);
  margin: 0;
  padding: 0;
}
.add_another span + span {
  margin-left: 8px;
}
.logout {
  position: fixed;
  top: 40px;
  right: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-radius: var(--inputRadius);
  outline: 0;
  border: 0;
  color: var(--danger);
  padding: 9px 16px;
  margin: 0;
  cursor: pointer;
}
.logout span + span {
  margin-left: 14px;
}

.butn1 {
  min-width: 120px;
}

.button_choose {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background: var(--white);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  border: transparent 1px solid;
}
.button_choose > :first-child {
  width: 56px;
}
.button_choose > :first-child .ikon {
  width: 100%;
  height: 56px;
  background-color: var(--grey1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_choose > :last-child {
  width: calc(100% - 72px);
  text-align: left;
}
.button_choose h5 {
  font-weight: 600;
}
.button_choose h6 {
  color: var(--grey);
}
.button_choose:hover,
.button_choose:active {
  border: var(--primary) 1px solid;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
}

.tab {
  font-weight: 500;
  font-size: 12px;
  color: var(--tableTh);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 100px;
  padding: 0 8px;
  border-radius: 18px;
}
.tab.active {
  background-color: var(--primary);
  color: var(--white);
}

.add_field {
  position: absolute;
  top: 5px;
  right: 6px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 15px;
}
.add_field svg,
.add_field path,
.remove_field svg,
.remove_field path {
  fill: var(--white);
}
.remove_field {
  position: absolute;
  top: 5px;
  right: 38px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--error);
  border-radius: 15px;
}
.del_field {
  position: absolute;
  top: 5px;
  right: 6px;
}
.button_browse {
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 24px;
  background-color: rgba(22, 25, 44, 1);
  color: var(--white);
  text-transform: uppercase;
  border: 0;
  border-radius: 10px;
}

.b_wid160 {
  width: 160px;
}

button[color='dark'] {
  color: var(--dark);
}
button[color='lableColor'] {
  color: var(--lableColor);
}
button[color='bg-primary'],
.button[color='bg-primary'] {
  background-color: var(--primary);
  color: var(--white);
}
button[color='bg-titary'],
.button[color='bg-titary'] {
  background-color: var(--titary);
  color: var(--white);
}
button[color='outline-titary'] {
  background-color: transparent;
  border: var(--titary) 1px solid;
  color: var(--titary);
}
button[color='bg-button'] {
  background-color: var(--buttonBg);
  color: var(--white);
}
button[color='outline-primary'] {
  background-color: transparent;
  border: var(--primary) 1px solid;
  color: var(--primary);
}
button[color='outline-grey'] {
  background-color: transparent;
  border: var(--grey) 1px solid;
  color: var(--grey);
}
button[color='outline-lite'] {
  background-color: transparent;
  border: var(--dark) 1px solid;
  color: var(--dark);
}
button[color='bg-danger'] {
  background-color: var(--danger);
  color: var(--white);
}
button[color='outline-danger'] {
  background-color: transparent;
  border: var(--danger) 1px solid;
  color: var(--danger);
}
