.block {
  min-width: 24%;
  height: 280px;
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
  flex: 0 0 20%;
}
.head {
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 50px;
}

.name {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: 5px;
}
.servetxt {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #1e1e1e;
  text-align: center;
  margin-top: -3px;
}
.actions {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-50%);
  /* padding: 0 var(--boxHPadding); */
}
.action_btn {
  border: 1px solid #edf2f7;
  border-radius: 4px;
  background-color: #fff;
  width: 25px;
  height: 120px;
  margin-top: 18px;
  align-items: center;
}
.carousal_item {
  max-width: 180px;
  margin-left: 1%;
}
.Item_box {
  width: 160px;
}
.caroousal_container {
  border: 1px solid black;
}
.imgbox {
  display: grid;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  width: 160px;
}

.img {
  width: 140px;
  height: 120px;
  border-radius: 5px;
}
