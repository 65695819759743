.header_bd1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_bd_left {
  display: flex;
  align-items: center;
  gap: 24px;
}
.header_bd_left > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.picture1 {
  width: 121px;
  overflow: hidden;
  padding: 4px;
  line-height: 0;
  border: 1px solid var(--lite);
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 4px;
}
.picture1 > div,
.picture1 img {
  border-radius: 4px;
}
.picture1 img {
  height: 113px;
  object-fit: contain;
}
.flex_row1 {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.flex_row2 {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
}
.flex_row2 > div {
  flex-grow: 1;
  padding-right: 20px;
  padding-left: 20px;
}
.icon1 {
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  background: var(--lite);
  border-radius: 5px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.row2_col {
  display: flex;
}
.row2_col + .row2_col {
  border-left: 1px solid #a6b7d4;
}
.row2_col > div + div {
  margin-left: 16px;
}
