.article {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
}
.layout1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.layout1 h1,
.layout1 h2,
.layout1 h3,
.layout1 p,
.layout2 h1,
.layout2 h2,
.layout2 h3,
.layout2 p {
  color: inherit;
}
.subTitleOuter {
  display: flex;
  justify-content: center;
}
.subTitleInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
}
.subTitle {
  text-align: center;
}
.subTitleOuter .hr {
  width: 100%;
  height: 1px;
}
.layout1Inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
.layout1Inner > div:nth-of-type(1) {
  width: calc(71% - 0px);
}
.layout1Inner > div:nth-of-type(2) {
  width: calc(29% - 24px);
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.slideOuter {
  display: flex;
  flex-direction: column;
  gap: 30px;
  white-space: wrap;
  line-height: normal;
  padding-bottom: 13px;
}
.categoryOuter {
  display: flex;
}
.category {
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.slide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
.slide > div:nth-of-type(1) {
  width: calc(50% - 24px);
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.slide > div:nth-of-type(2) {
  width: calc(50% - 0px);
}
.slide picture {
  display: flex;
  line-height: 0;
}
.slide img {
  width: 100%;
  height: auto;
}
.slide h1 {
  color: inherit;
}

.layout2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 40px;
  max-width: 1136px;
  margin-left: auto;
  margin-right: auto;
}
.layout2 .categoryOuter {
  justify-content: center;
}
.layout2 .category {
  gap: 10px;
}
.layout2 .slideOuter {
  padding-bottom: 0;
}
.layout2 .slide {
  padding: 41px;
  position: relative;
  border-radius: 24px;
  z-index: 1;
}
.slideOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 24px;
  z-index: -1;
}
.layout2 .slide > div:nth-of-type(1) {
  width: calc(100% - 0px);
}
.layout2 .slide > div:nth-of-type(2) {
  width: calc(50% - 100px);
}
.layout2 .slide > div:nth-of-type(3) {
  width: calc(50% - 0px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
}

.mobileView .layout1Inner {
  flex-direction: column-reverse;
}
.mobileView .layout1Inner > div:nth-of-type(1) {
  width: 100%;
}
.mobileView .layout1Inner > div:nth-of-type(2) {
  width: 100%;
}
.mobileView .slide {
  flex-direction: column-reverse;
}
.mobileView .slide > div:nth-of-type(1) {
  width: 100%;
}
.mobileView .slide > div:nth-of-type(2) {
  width: 100%;
}

@media (max-width: 991px) {
  .layout1Inner {
    flex-direction: column-reverse;
  }
  .layout1Inner > div:nth-of-type(1) {
    width: 100%;
  }
  .layout1Inner > div:nth-of-type(2) {
    width: 100%;
  }
  .slide {
    flex-direction: column-reverse;
  }
  .slide > div:nth-of-type(1) {
    width: 100%;
  }
  .slide > div:nth-of-type(2) {
    width: 100%;
  }
}
