.article {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: rgba(var(--black-rgb), 0.86);
}
.article label {
  font-size: 12px;
  font-weight: 600;
}
.itemBx {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.itemBx button + button {
  margin-left: 0;
}
.colorItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  padding: 0;
  border-radius: 50%;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  overflow: hidden;
}
.buttonNewColor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  padding: 0;
  border-radius: 50%;
  border: 1px solid var(--borderColor1);
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
