.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 2px 2px 16px var(--greyO1p6);
}
.badge {
  font-weight: 600;
  font-size: 10px;
  background-color: #e1e8ff;
  color: var(--primary);
  height: 19px;
  line-height: 19px;
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 12px;
  margin-left: 8px;
}
