.bx {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: var(--white);
  border: transparent 1px solid;
  border-radius: 10px;
}
.bx:focus-within {
  border: var(--primary) 1px solid;
}
.label {
  font-size: 12px;
  font-weight: 400;
  color: var(--lableColor);
  line-height: var(--lableLineHeight);
  padding: 16px 24px 0;
}
.error {
  font-size: 11px;
  line-height: 12px;
  color: var(--error);
  margin-top: 8px;
}
