.fieldbox {
  width: 100%;
}
.fieldbox + .fieldbox {
  margin-top: 4px;
}
.field {
  display: flex;
  align-items: center;
}
.required {
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translate(-100%, -50%);
  color: var(--error);
}
.upload {
  position: relative;
  width: 100%;
  min-height: 106px;
  background-color: #f9fff9;
  border: #8b8ba9 1px dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}
.filefield {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  opacity: 0;
  transition: all 0.2s ease;
}
.label {
  font-weight: 500;
  font-size: 14px;
  color: var(--lableColor);
}
.infobox {
  position: relative;
  padding: 2px;
  z-index: 1;
}
.infoChild {
  position: absolute;
  top: 0;
  left: 40px;
  display: flex;
  opacity: 0;
  visibility: hidden;
  z-index: -10;
}
.infoChild svg {
  margin: 4px 4px 0 0;
}
.infoContent {
  width: 264px;
  background-color: var(--dark);
  color: var(--white);
  padding: 16px;
  border-radius: var(--bgRadius);
}
.infobox:hover .infoChild {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}
