.close {
  position: fixed;
  top: 0;
  right: 0;
}
.closeR {
  position: absolute;
  top: 0;
  right: 0;
}
.close_button {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  margin: 40px;
}
.close_button svg {
  width: 24px;
  height: auto;
}
.closeR .close_button svg {
  width: 14px;
  height: auto;
}
.close_button svg,
.close_button path {
  fill: var(--white);
}
.closeR .close_button svg,
.closeR .close_button path {
  fill: var(--asideNavColor);
}
