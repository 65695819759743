.article {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 0;
}
.r1Bx {
    display: flex;
    flex-direction: row;
    gap: 22px;
}
.r1Bx > div {
    padding-top: 24px;
    padding-bottom: 24px;
}
.left {
    width: 244px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-right: 22px;
    border-right: #0000001F 2px solid;
}
.middle {
    width: calc(52% - 122px);
}
.right {
    width: calc(48% - 122px);
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding-left: 22px;
    border-left: #0000001F 2px solid;
}
.accList {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.formulaBx {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px;
    background: var(--white);
    border-radius: 8px;
}
.formulaBx h4 {
    font-weight: 600;
}
.ingList {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.ingItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
}
.ingItem > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
}
.ingItem h6 {
    font-size: 14px;
    font-weight: 500;
}
.inputQty,
.inputUnit {
    width: 50px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    color: #000000;
    border: #00000033 1px solid;
    border-radius: 6px;
    background: var(--white);
    box-shadow: none;
}
.inputUnit {
    margin: 0;
    padding: 0;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}
.unitItem {
    position: relative;
}
.unitDropdownList {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 14px 10px;
    min-width: 83px;
    background: var(--white);
    border: #00000033 1px solid;
    border-radius: 8px;
    z-index: 12;
}
.unitDropdownItem {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    line-height: normal;
    margin: 0;
    padding: 0;
    text-align: left;
    background: transparent;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}
.buttonIng {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    background: transparent;
    border: #FF0000 1px solid;
    border-radius: 50%;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}
.buttonIng svg {
    width: 8px;
    height: auto;
    fill: #FF0000;
}
.right h4 {
    font-weight: 600;
}
.stepList {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.stepItem {
    display: flex;
    flex-direction: row;
    gap: 13px;
}
.stepItem > div:nth-of-type(1) {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
}
.stepItem > div:nth-of-type(2) {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: var(--black);
}
.stepItem > div:nth-of-type(3) {
    width: 16px;
}