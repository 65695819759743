.layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 0;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 0px;
}
.header > div {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 18px;
  background: var(--white);
  border-radius: 20px;
  padding: 14.25px 15px;
  min-height: 98px;
}
.headItemContent {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.header p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(163, 174, 208, 1);
}
.header h3 {
  font-weight: 700;
  color: rgba(43, 54, 116, 1);
}
.header p small {
  font-size: 12px;
  font-weight: 400;
  color: rgba(163, 174, 208, 1);
}
.header p small span {
  font-weight: 700;
  color: rgba(5, 205, 153, 1);
}
.iconBx {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(250, 245, 255, 1);
}

.flex_1 {
  display: flex;
  gap: 20px;
}
.flex_1 > div:nth-of-type(1) {
  width: calc(60.71% - 10px);
  background: var(--white);
  border-radius: 29px;
  padding: 21px;
}
.flex_1 > div:nth-of-type(2) {
  width: calc(39.29% - 10px);
  background: var(--white);
  border-radius: 29px;
  padding: 21px;
}

.flex_2 {
  display: flex;
  gap: 20px;
}
.flex_2 > div {
  background: var(--white);
  border-radius: 29px;
  padding: 21px;
}
.flex_2 > div:nth-of-type(1),
.flex_2 > div:nth-of-type(3) {
  flex: 1;
}
.flex_2 > div:nth-of-type(2) {
  width: 257px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
