.header {
  position: sticky;
  top: 0;
  background-color: var(--white);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  z-index: var(--headerZIndex);
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-top: 16px;
  padding-bottom: 15px;
}
.flex > div:nth-of-type(2) {
  display: flex;
  align-items: center;
  gap: 16px;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}
.logo a {
  display: flex;
  line-height: 0;
}
.logo > a svg {
  width: 100px;
  height: auto;
}
.headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.headerRight button + button {
  margin-left: 0;
}
.portfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 100px;
  background: #f6effb;
  border-radius: 32px;
}
.buttonMenu {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: transparent;
  line-height: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.flexRowG7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}
.buttonBack {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: transparent;
  line-height: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.flexRowGM5 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flexRowGM5 > div + div {
  margin-left: calc(5px * -1);
}
.wayItem {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 50%;
}
.wayTip {
  position: absolute;
  top: 100%;
  left: 50%;
  display: none;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  white-space: nowrap;
  padding: 10px 10px;
  border-radius: 6px;
  margin-top: 8px;
  transform: translateX(-50%);
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1),
    0px 8px 8px 0px rgba(0, 0, 0, 0.09), 0px 18px 11px 0px rgba(0, 0, 0, 0.05),
    0px 33px 13px 0px rgba(0, 0, 0, 0.01), 0px 51px 14px 0px rgba(0, 0, 0, 0);
}
/* .wayTip::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 100%;
  width: 0;
  height: 0;
  border-right: transparent 7px solid;
  border-left: transparent 7px solid;
  border-bottom: black 11px solid;
  transform: translateX(-50%);
} */
.wayItem:hover .wayTip {
  display: block;
}
.businessItem {
  background: #373f51;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  text-transform: uppercase;
  border: 0;
}
.brandItem {
  line-height: 0;
  background: transparent;
  border: var(--primary) 1px solid;
}
.brandItem picture {
  display: flex;
  line-height: 0;
}
.brandItem img {
  width: 22px;
  height: 22px;
  object-fit: contain;
  border-radius: 50%;
}
.headerRight > button,
.backButton > button {
  width: 85px;
  height: 33px;
  border-radius: 8px;
}
.buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}
.logout {
  width: 214px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--danger);
  text-align: left;
  padding: 25px 25px 23px;
  background-color: var(--white);
  border-radius: 8px;
}
.logout span + span {
  margin-left: 12px;
}
.logout svg {
  width: 16px;
  height: auto;
}

.screen_views {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.web_view_button {
  width: 85px;
}
.mobile_view_button {
  width: 33px;
}
.web_view_button,
.mobile_view_button {
  height: 33px;
  background: rgba(var(--black-rgb), 0.08);
  padding: 0;
  line-height: 0;
  border-radius: 5px;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
