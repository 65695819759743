.article {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: rgba(var(--black-rgb), 0.86);
}
.article label {
  font-size: 12px;
  font-weight: 600;
}
.article div {
  width: 100%;
}
.article textarea {
  width: 100%;
  min-height: 108px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(var(--black-rgb), 0.86);
  background: transparent;
  border: var(--borderColor1) 1px solid;
  border-radius: 10px;
  box-shadow: none;
  resize: none;
  outline: 0;
}
.article textarea:focus,
.article textarea:focus-within {
  box-shadow: none;
  outline: 0;
}
.article textarea::-webkit-input-placeholder {
  color: rgba(var(--black-rgb), 0.24);
  font-weight: 400;
}
.article textarea::-moz-placeholder {
  color: rgba(var(--black-rgb), 0.24);
  font-weight: 400;
}
.article textarea:-ms-input-placeholder {
  color: rgba(var(--black-rgb), 0.24);
  font-weight: 400;
}
.article textarea:-moz-placeholder {
  color: rgba(var(--black-rgb), 0.24);
  font-weight: 400;
}

.error {
  font-size: 12px;
  color: red;
  margin-top: 4px;
}
