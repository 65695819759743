.bx {
  display: flex;
  flex-direction: column;
  gap: 19px;
}
.bx h4 {
  font-weight: 700;
  color: rgba(14, 19, 47, 1);
}

.table {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.tr {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(252, 249, 255, 1);
  border-radius: 6px;
}
.tr > div {
  padding: 7.5px 16px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(46, 42, 42, 1);
}
.tr > div:nth-of-type(1) {
  width: 100%;
}
.tr > div:nth-of-type(2),
.tr > div:nth-of-type(3) {
  width: 30%;
  text-align: center;
}
.thead {
  background: transparent;
}
.thead > div {
  padding: 0px 16px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(66, 66, 66, 1);
}
