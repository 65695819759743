.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 2px 2px 16px var(--greyO1p6);
}
.box button + button {
  margin-left: 14px;
}
