.bx {
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 24px 0;
}

.container {
  background: #ffffff;
  border-radius: 12px;
}

.legendRw {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px;
  padding: 8px;
}

.legendItem {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: #f7fafc;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.legendItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.colorSquare {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 12px;
  transition: transform 0.2s ease;
}

.legendItem:hover .colorSquare {
  transform: scale(1.1);
}

.itemContent {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.categoryName {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
}

.value {
  font-size: 14px;
  font-weight: 600;
  color: #2d3748;
  background: rgba(0, 0, 0, 0.04);
  padding: 4px 8px;
  border-radius: 4px;
}
