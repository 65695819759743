.bx {
    display: flex;
    flex-direction: column;
    gap: 19px;
}
.bx h4 {
    font-weight: 700;
    color: rgba(14, 19, 47, 1);

}

.legendRw {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
}
.legendRw > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.legendRw > div h3 {
    font-weight: 700;
    color: rgba(32, 34, 36, 1);
}
.legendRw > div p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7.5px;
    font-weight: 500;
    color: rgba(40, 45, 50, 1);
}
.legendRw > div p span {
    width: 12px;
    height: 12px;
    border-radius: 12px;
}