.pt_32 {
  padding-top: 32px;
}
.search_grid {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 16px;
}
.search_grid > div {
  flex: 1 1 0;
}
