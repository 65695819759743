.box {
  position: relative;
  background-color: var(--inputBackground);
  background-image: url(./../../assets/Images/select.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 11px) center;
}
.select {
  font-weight: 500;
  font-size: var(--inputFontSize);
  width: 100%;
  height: var(--inputHeight);
  line-height: var(--inputLineHeight);
  padding-right: calc(var(--inputHPadding) + 11px);
  padding-left: var(--inputHPadding);
  background-color: transparent;
  color: var(--inputColor);
  border: var(--inputBorder);
  border-radius: var(--inputRadius);
  outline: 0;
  transition: all 0.2s ease;
}
.fieldSm {
  font-size: var(--inputSmFontSize);
  padding-right: calc(var(--inputSmHPadding) + 11px);
  padding-left: var(--inputSmHPadding);
}
.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.select::-ms-expand {
  display: none;
}
.select::-webkit-input-placeholder {
  color: var(--inputPlaceholderColor);
}
.select:-moz-placeholder {
  color: var(--inputPlaceholderColor);
  opacity: 1;
}
.select::-moz-placeholder {
  color: var(--inputPlaceholderColor);
  opacity: 1;
}
.select:-ms-input-placeholder {
  color: var(--inputPlaceholderColor);
}
.select::-ms-input-placeholder {
  color: var(--inputPlaceholderColor);
}
.select::placeholder {
  color: var(--inputPlaceholderColor);
}
.label {
  font-weight: 500;
  font-size: 14px;
  line-height: var(--lableLineHeight);
  color: var(--lableColor);
}
