:root {
  --rs-primary-50: #edf2f7;
  --rs-primary-100: #d9baf4;
  --rs-primary-200: #b789da;
  --rs-primary-300: #b789da;
  --rs-primary-400: #b789da;
  --rs-primary-500: #b789da;
  --rs-primary-600: #a561d8;
  --rs-primary-700: #a561d8;
  --rs-primary-800: #9747ff;
  --rs-primary-900: #8d23dd;

  --rs-color-red: #dd0000;
  --rs-color-orange: #f16063;
  --rs-color-yellow: #f6a000;
  --rs-color-green: #66cb9f;
  --rs-color-cyan: #718096;
  --rs-color-blue: #a561d8;
  --rs-color-violet: #673ab7;
}

.rs-table-row-header .rs-table-cell {
  background: transparent;
}
.rs-table-row-header {
  border-radius: 0;
}
.rs-table-cell-header:first-child .rs-table-cell {
  border-radius: 0;
}
.rs-table-cell-header:last-child .rs-table-cell {
  border-radius: 0;
}
.rs-table-cell {
  box-shadow: none;
  border-bottom: 0;
}
.rs-table-cell-content {
  font-size: 14px;
  line-height: normal;
  color: rgba(110, 112, 121, 1);
  padding: 14px 8px;
}
.rs-table-cell-header .rs-table-cell-content {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06em;
  color: rgba(44, 45, 51, 1);
  text-transform: none;
  padding: 14px 8px;
  border-top: rgba(225, 226, 233, 1) 1px solid;
  border-bottom: rgba(225, 226, 233, 1) 1px solid;
}
.rs-table-cell-first .rs-table-cell-content,
.rs-table-cell-header:first-child .rs-table-cell,
.rs-table-cell-header:first-child .rs-table-cell-content {
  padding-left: 0;
}
.rs-table-cell-last .rs-table-cell-content,
.rs-table-cell-header:last-child .rs-table-cell,
.rs-table-cell-header:last-child .rs-table-cell-content {
  padding-right: 0;
}
.rs-table-cell-content strong {
  font-weight: 500;
}
.rs-table-cell-content .fsemi {
  font-weight: 600;
}
.rs-table-cell-content b {
  font-weight: 700;
}

.rs-table-row-expanded {
  padding: 8px 0;
}

.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
.rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row:hover .rs-table-cell {
  background-color: var(--lite);
}
.rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row-header:hover .rs-table-cell {
  background-color: var(--bgTableThHover);
}

.rs-pagination-group-xs,
.rs-pagination-group-total {
  font-size: 14px;
  line-height: 23px;
  color: var(--paginationColor);
}
.rs-pagination {
  display: flex;
  align-items: center;
}
.rs-pagination-symbol {
  height: 23px;
}
.rs-pagination-symbol svg {
  height: 18px;
  width: auto;
}
.rs-pagination-symbol svg,
.rs-pagination-symbol path {
  fill: var(--paginationColor);
}
.rs-pagination-xs .rs-pagination-btn {
  font-size: 14px;
  line-height: 26px;
  padding: 1px 7px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.rs-pagination-btn.rs-pagination-btn-active:hover,
.rs-pagination-btn.rs-pagination-btn-active {
  font-weight: 600;
  color: var(--paginationColor);
  background-color: var(--paginationBg);
  border: 1px solid var(--paginationBg);
}
.rs-pagination-btn:disabled,
.rs-pagination-btn.rs-btn-disabled {
  opacity: 0.5;
}

.rs-rate-character {
  margin-right: 1px;
}
