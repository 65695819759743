.emptyBox {
  background-color: var(--grey1);
  border-radius: var(--inputRadius);
  padding: calc(var(--boxPadding) * 2);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.emptyBox > div:first-child {
  max-width: 340px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  min-height: 360px;
}
