.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
