.bx {
    display: flex;
    flex-direction: column;
    gap: 19px;
}
.bx h4 {
    font-weight: 700;
    color: rgba(14, 19, 47, 1);

}

.table {
    display: flex;
    flex-direction: column;
}
.tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 0;
}
.tr + .tr {
    border-top: rgba(230, 230, 230, 1) 1px solid;
}
.tr > div:nth-of-type(1) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(43, 54, 116, 1);
}
.tr > div:nth-of-type(2) {
    min-width: 37px;
    height: 37px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(43, 54, 116, 1);
    background: rgba(250, 245, 255, 1);
}