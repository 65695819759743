.bx {
  display: flex;
  flex-direction: column;
  width: 49.5%;
  gap: 16px;
  margin-top: 16px;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}
.row > div {
  flex: 1;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}
