.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  height: 36px;
  padding: 6px 16px;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
