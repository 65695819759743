.controler {
  position: relative;
  display: flex;
}
.button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  box-shadow: none;
  background-color: transparent;
}
.dropdown {
  position: absolute;
  top: 100%;
}

.account {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin: 0;
  border-radius: 50%;
  border: 0;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  background: var(--white);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
}
.account span {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--white);
  background: var(--primary);
}
