.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.header > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.header h2 {
  font-weight: 600;
  line-height: normal;
}
.header h5 {
  font-weight: 500;
  line-height: normal;
}

.chooseType {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.chooseType > div {
  flex: 1;
}
.typeButton {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  background: var(--white);
  color: inherit;
  text-align: left;
  padding: 14.5px 16.5px;
  border: transparent 2px solid;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.typeButton > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.typeButton h6 {
  font-weight: 600;
}
.typeButton p {
  font-weight: 500;
  min-height: 40px;
}
.typeButton:hover,
.typeButton:active,
.typeButton.active {
  border: var(--primary) 2px solid;
}
.typeButton:hover svg,
.typeButton:active svg,
.typeButton.active svg {
  fill: var(--primary);
}
