.tabsBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabs {
  display: flex;
  align-items: center;
}
.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  min-width: 100px;
  height: 32px;
  font-weight: 500;
  font-size: 11px;
  text-transform: capitalize;
  background: var(--lite1);
  color: var(--dark);
  border-radius: 16px;
  border: 3px solid var(--lite1);
  padding: 0 8px;
  margin: 0;
  outline: 0;
  box-shadow: none;
}
.tab.active {
  background: var(--primary);
  border: 3px solid var(--primary);
  color: var(--white);
}
.panels {
  margin-top: 16px;
}
.panel {
  display: none;
  padding: 0;
}
.panel.active {
  display: block;
}
