.layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    min-height: calc(100vh - 69px);
}
.footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 32px;
    padding: 21px 0 48px;
    border-top: rgba(29, 25, 33, 0.42) 1px solid;
}
.footer > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: var(--black);
}
.footer > div h2 {
    font-weight: 700;
}
.footer > div h4 {
    font-weight: 400;
}