.article {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  min-height: calc(100vh - 64px);
}
.article > div:nth-of-type(1) {
  width: 250px;
  align-self: stretch;
  background-color: white;
  transition: width 0.35s ease;
}
.article > div:nth-of-type(2) {
  width: calc(100% - 250px);
  align-self: stretch;
  transition: width 0.35s ease;
}
.article.articleSmart > div:nth-of-type(1) {
  width: 82px;
}
.article.articleSmart > div:nth-of-type(2) {
  width: calc(100% - 82px);
}
