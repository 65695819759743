.aside {
  width: 100%;
  position: sticky;
  top: var(--headerHeight);
  padding: 16px;
  background-color: var(--white);
  max-height: calc(100vh - var(--headerHeight));
  z-index: calc(var(--headerZIndex) - 10);
}
.navItem {
  position: relative;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  color: #16192c;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding: 16.5px 16px;
  border: 0;
  border-radius: 12px;
  background: transparent;
  box-shadow: none;
  outline: 0;
  cursor: pointer;
  z-index: 1;
}
.tooltip {
  position: absolute;
  top: 50%;
  left: 100%;
  display: none;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  white-space: nowrap;
  padding: 10px 10px;
  border-radius: 6px;
  margin-left: 8px;
  transform: translateY(-50%);
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1),
    0px 8px 8px 0px rgba(0, 0, 0, 0.09), 0px 18px 11px 0px rgba(0, 0, 0, 0.05),
    0px 33px 13px 0px rgba(0, 0, 0, 0.01), 0px 51px 14px 0px rgba(0, 0, 0, 0);
}
.navItem:hover .tooltip {
  display: block;
}
.itemSmtArrow {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
}
.navItem:hover {
  background: #f6effb;
}
.navItem.active {
  background: var(--primary);
  color: white;
}
.subMenu .navItem {
  color: #16192c;
  padding: 9.5px 16px;
  border-radius: 6px;
}
.subMenu.smartSubMenu .navItem {
  padding: 16.5px 16px;
  border-radius: 12px;
}
.subMenu .navItem.active {
  background: var(--primary);
  color: white;
}
.navItem > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.icon {
  width: 18px;
  line-height: 0;
}
.navItem.active .icon path {
  fill: white;
}
.subMenu {
  position: relative;
  background: transparent;
  z-index: 1;
  padding: 10px;
}
.subMenu.smartSubMenu {
  padding: 0;
}
