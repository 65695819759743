.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  padding-top: 24px;
  padding-bottom: 16px;
}
.header > div {
  flex: 1;
}
.headCard {
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 16px;
  border-radius: 8px;
  color: var(--white);
}
.headCard > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.headCard p {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
}
.headCard h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
}
.headCardIcon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  line-height: 0;
}
.content {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-bottom: 40px;
}
.content > div:nth-of-type(1) {
  flex: 1;
}
.content > div:nth-of-type(2) {
  width: 39%;
}
.cardBx {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: var(--white);
  border-radius: 12px;
  padding: 16px 20px;
}
.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.cardHeader > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.cardHeader h6 {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
}
.badge {
  background: #ece6f7;
  padding: 6.5px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary);
  border-radius: 8px;
}
.dateFilter {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  color: #1c1d22;
}
.fRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-top: 16px;
}
.fRow > *,
.fRow > div {
  flex: 1;
}
.fRow > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.primaryCard {
  width: 100%;
  min-height: 159px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  background: var(--primary);
  color: var(--white);
  border-radius: 12px;
  padding: 11px 15px;
}
.primaryCard > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.primaryCard > div:nth-of-type(2) > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.primaryCard p {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
}
.primaryCard h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}
.primaryCard h4 small {
  font-size: 12px;
  font-weight: 400;
  color: #dbdeee;
}
.primaryCardIcon {
  width: 36px;
  height: 36px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.16);
}
.primaryCardIcon svg {
  width: 20px;
  height: auto;
}
.inventoryList {
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}
.listHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  background: #f7f9fc;
  border-bottom: 1px solid #e2e8f0;
}
.listHeader span {
  font-size: 14px;
  font-weight: 600;
  color: #4a5568;
  flex: 1;
}
.listHeader span:last-child {
  text-align: right;
  flex: 0 0 100px;
}
.listItem {
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: 1px solid #edf2f7;
  transition: background-color 0.2s ease;
}
.listItem:last-child {
  border-bottom: none;
}
.listItem:hover {
  background-color: #f7fafc;
}
.productName {
  font-size: 14px;
  color: #2d3748;
  flex: 1;
  padding-right: 16px;
}
.quantity {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary);
  flex: 0 0 100px;
  text-align: right;
  background: #f0f4ff;
  padding: 4px 12px;
  border-radius: 6px;
}
.noData {
  padding: 32px;
  text-align: center;
  color: #718096;
  font-size: 14px;
  background: #f7f9fc;
}
