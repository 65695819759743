.article {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
}
.slide {
  position: relative;
  z-index: 1;
}
.slide picture {
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 56.6%;
  line-height: 0;
}
.slide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  white-space: wrap;
  z-index: 24;
}
.contentInner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 460px;
}
.contentInner h1 {
  color: inherit;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 12;
}
