* {
  border: 0;
  outline: 0;
  box-shadow: none;
  list-style-type: none;
  box-sizing: border-box;
}
html,
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: inherit;
}
a,
a:focus,
a:hover {
  color: var(--primary);
}
a.label,
a.label:focus,
a.label:hover,
.label a,
.label a:focus,
.label a:hover {
  color: var(--lableColor);
}
html,
body {
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
}
body {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #edf2f7;
  color: #000000;
}

strong {
  font-weight: 500;
}
b {
  font-weight: 700;
}
h1 {
  font-size: 34px;
  color: var(--dark);
  line-height: normal;
  letter-spacing: 0.22px;
}
h2 {
  font-size: 28px;
  color: var(--dark);
  line-height: normal;
}
h3 {
  font-size: 24px;
  line-height: normal;
}
h4 {
  font-size: 20px;
  color: var(--dark);
  line-height: normal;
}
h5 {
  font-size: 18px;
  color: var(--dark);
  line-height: normal;
  letter-spacing: -0.4px;
}
h6 {
  font-size: 16px;
  color: var(--dark);
  line-height: normal;
}
p {
  line-height: normal;
}
picture {
  line-height: 0;
}
picture img {
  width: 100%;
  height: auto;
  border: 0;
}
picture svg {
  width: 100%;
  height: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  appearance: none !important;
  margin: 0 !important;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

hr {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  border-top: var(--borderColor1) 1px solid;
  opacity: 1;
}
.hr1 {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  border-top: var(--lite) 1px solid;
  opacity: 1;
}

.contain-bx {
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
}

.f10,
[size='f10'] {
  font-size: 10px;
}
.f11,
[size='f11'] {
  font-size: 11px;
}
.f12,
[size='f12'] {
  font-size: 12px;
}
.f13,
[size='f13'] {
  font-size: 13px;
}
.f14,
[size='f14'] {
  font-size: 14px;
}
.f16,
[size='f16'] {
  font-size: 16px;
}
.f18,
[size='f18'] {
  font-size: 18px;
}
.f20,
[size='f20'] {
  font-size: 20px;
}

dl dt {
  margin: 0;
  padding: 0;
}
dl dd {
  margin: 0;
  padding: 0;
}
dl dd + dt {
  margin-top: 16px;
}
.dl_flex {
  display: flex;
  flex-wrap: wrap;
}
.dl_flex dt {
  width: 150px;
}
.dl_flex dd {
  width: calc(100% - 166px);
}

[color='primary'] {
  color: var(--primary);
}
[color='primary'] svg {
  fill: var(--primary);
}
[color='secondary'] {
  color: var(--secondary);
}
[color='secondary'] svg,
[color='secondary'] path {
  fill: var(--secondary);
}
[color='titary'] {
  color: var(--titary);
}
[color='danger'] {
  color: var(--danger);
}
[color='danger'] svg,
[color='danger'] path {
  fill: var(--danger);
}
[color='black'] {
  color: var(--black);
}
[color='white'] {
  color: var(--white);
}
[color='bg-primary'] {
  background-color: var(--primary);
}
[color='bg-secondary'] {
  background-color: var(--secondary);
}
[color='bg-titary'] {
  background-color: var(--titary);
}
[color='bg-white'] {
  background-color: var(--white);
}
[color='bg-black'] {
  background-color: var(--black);
}
[color='greylite'] {
  color: var(--greylite);
}
[color='liteLabel'] {
  color: var(--liteLabel);
}
[color='tableTh'] {
  color: var(--tableTh);
}
input[type='file'].hide,
input[type='file'].hidden {
  position: absolute;
  top: 0;
  left: 0;
}
.hide,
.hidden {
  opacity: 0;
}
.placeholder svg {
  width: 100%;
  height: 100%;
}

.browse_input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.loading_full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: calc(var(--headerZIndex) + 110);
}

.PhoneInput {
  width: 100%;
  background-color: var(--inputBackground);
  color: var(--inputColor);
  border: var(--inputBorder);
  border-radius: var(--inputRadius);
  padding-left: var(--inputHPadding);
}
.PhoneInput input {
  display: flex;
  align-items: center;
  height: var(--inputHeight);
  padding: 0 var(--inputHPadding);
  background: transparent;
}

.text_field {
  font-weight: 500;
  font-size: var(--inputFontSize);
  width: 100%;
  height: var(--inputHeight);
  line-height: var(--inputLineHeight);
  padding-right: var(--inputHPadding);
  padding-left: var(--inputHPadding);
  background-color: var(--inputBackground);
  color: var(--inputColor);
  border: var(--inputBorder);
  border-radius: var(--inputRadius);
  outline: 0;
  transition: all 0.2s ease;
}
.text_field::-webkit-input-placeholder {
  color: var(--inputPlaceholderColor);
}
.text_field:-moz-placeholder {
  color: var(--inputPlaceholderColor);
  opacity: 1;
}
.text_field::-moz-placeholder {
  color: var(--inputPlaceholderColor);
  opacity: 1;
}
.text_field:-ms-input-placeholder {
  color: var(--inputPlaceholderColor);
}
.text_field::-ms-input-placeholder {
  color: var(--inputPlaceholderColor);
}
.text_field::placeholder {
  color: var(--inputPlaceholderColor);
}

.mobile_view h1 {
  font-size: 24px;
  line-height: normal;
  letter-spacing: normal;
}
.mobile_view h2 {
  font-size: 21px;
  line-height: normal;
}
.mobile_view h3 {
  font-size: 19px;
}
.mobile_view h4 {
  font-size: 17px;
  line-height: normal;
}
.mobile_view h5 {
  font-size: 15px;
  line-height: normal;
  letter-spacing: normal;
}
.mobile_view h6 {
  font-size: 13px;
  line-height: normal;
}

@media (max-width: 991px) {
  h1 {
    font-size: 24px;
    line-height: normal;
    letter-spacing: normal;
  }
  h2 {
    font-size: 21px;
    line-height: normal;
  }
  h3 {
    font-size: 19px;
  }
  h4 {
    font-size: 17px;
    line-height: normal;
  }
  h5 {
    font-size: 15px;
    line-height: normal;
    letter-spacing: normal;
  }
  h6 {
    font-size: 13px;
    line-height: normal;
  }
}

/* builder global css */
.builder_items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.builder-layout-items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.builder-layout-items > div {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
}
.builder-button-link {
  font-size: 13px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  background: transparent;
  outline: 0;
  border: 0;
  box-shadow: none;
  cursor: pointer;
}
.builder-button-link[color='gray'] {
  color: var(--grey);
}
.builder-button-link[color='primary'] {
  color: var(--primary);
}
/* /builder global css */

/* builder theme css */
.contBx {
  width: 100%;
  max-width: var(--builder-theme-container-max-width);
  /* padding-left: var(--builder-theme-horizontal-padding);
  padding-right: var(--builder-theme-horizontal-padding); */
}
/* /builder theme css */
