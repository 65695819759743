.aside_control {
  padding: 0 var(--asideBodyHPadding) var(--asideBodyHPadding);
  background-color: var(--lite);
  transition: all ease 0.25s;
  min-height: 100vh;
}
.m_5 {
  margin: 5px;
}
.m_8 {
  margin: 8px;
}
.m_10 {
  margin: 10px;
}
.m_15 {
  margin: 15px;
}
.m_20 {
  margin: 20px;
}
.m_25 {
  margin: 25px;
}
.m_30 {
  margin: 30px;
}
.m_35 {
  margin: 35px;
}
.m_40 {
  margin: 40px;
}
.mt_4 {
  margin-top: 4px;
}
.mt_5 {
  margin-top: 5px;
}
.mt_8 {
  margin-top: 8px;
}
.mt_10 {
  margin-top: 10px;
}
.mt_12 {
  margin-top: 12px;
}
.mt_15 {
  margin-top: 15px;
}
.mt_16 {
  margin-top: 16px;
}
.mt_20 {
  margin-top: 20px;
}
.mt_24 {
  margin-top: 24px;
}
.mt_25 {
  margin-top: 25px;
}
.mt_26 {
  margin-top: 26px;
}
.mt_28 {
  margin-top: 28px;
}
.mt_30 {
  margin-top: 30px;
}
.mt_32 {
  margin-top: 32px;
}
.mt_35 {
  margin-top: 35px;
}
.mt_40 {
  margin-top: 40px;
}
.mt_46 {
  margin-top: 46px;
}
.mr_5 {
  margin-right: 5px;
}
.mr_8 {
  margin-right: 8px;
}
.mr_10 {
  margin-right: 10px;
}
.mr_15 {
  margin-right: 15px;
}
.mr_20 {
  margin-right: 20px;
}
.mr_25 {
  margin-right: 25px;
}
.mr_30 {
  margin-right: 30px;
}
.mr_35 {
  margin-right: 35px;
}
.mr_40 {
  margin-right: 40px;
}
.mb_5 {
  margin-bottom: 5px;
}
.mb_8 {
  margin-bottom: 8px;
}
.mb_10 {
  margin-bottom: 10px;
}
.mb_15 {
  margin-bottom: 15px;
}
.mb_20 {
  margin-bottom: 20px;
}
.mb_25 {
  margin-bottom: 25px;
}
.mb_30 {
  margin-bottom: 30px;
}
.mb_35 {
  margin-bottom: 35px;
}
.mb_40 {
  margin-bottom: 40px;
}
.ml_5 {
  margin-left: 5px;
}
.ml_8 {
  margin-left: 8px;
}
.ml_10 {
  margin-left: 10px;
}
.ml_15 {
  margin-left: 15px;
}
.ml_20 {
  margin-left: 20px;
}
.ml_25 {
  margin-left: 25px;
}
.ml_30 {
  margin-left: 30px;
}
.ml_35 {
  margin-left: 35px;
}
.ml_40 {
  margin-left: 40px;
}

.p10 {
  padding: 10px;
}
.p12 {
  padding: 12px;
}
.p16 {
  padding: 16px;
}
.p_5 {
  padding: 5px;
}
.p_10 {
  padding: 10px;
}
.p_15 {
  padding: 15px;
}
.p_20 {
  padding: 20px;
}
.p_25 {
  padding: 25px;
}
.p_30 {
  padding: 30px;
}
.p_35 {
  padding: 35px;
}
.p_40 {
  padding: 40px;
}
.pt_5 {
  padding-top: 5px;
}
.pt_8 {
  padding-top: 8px;
}
.pt_10 {
  padding-top: 10px;
}
.pt_15 {
  padding-top: 15px;
}
.pt_20 {
  padding-top: 20px;
}
.pt_25 {
  padding-top: 25px;
}
.pt_30 {
  padding-top: 30px;
}
.pt_35 {
  padding-top: 35px;
}
.pt_40 {
  padding-top: 40px;
}
.pr_5 {
  padding-right: 5px;
}
.pr_10 {
  padding-right: 10px;
}
.pr_15 {
  padding-right: 15px;
}
.pr_20 {
  padding-right: 20px;
}
.pr_25 {
  padding-right: 25px;
}
.pr_30 {
  padding-right: 30px;
}
.pr_35 {
  padding-right: 35px;
}
.pr_40 {
  padding-right: 40px;
}
.pb_5 {
  padding-bottom: 5px;
}
.pb_10 {
  padding-bottom: 10px;
}
.pb_15 {
  padding-bottom: 15px;
}
.pb_20 {
  padding-bottom: 20px;
}
.pb_25 {
  padding-bottom: 25px;
}
.pb_30 {
  padding-bottom: 30px;
}
.pb_35 {
  padding-bottom: 35px;
}
.pb_40 {
  padding-bottom: 40px;
}
.pl_5 {
  padding-left: 5px;
}
.pl_10 {
  padding-left: 10px;
}
.pl_15 {
  padding-left: 15px;
}
.pl_20 {
  padding-left: 20px;
}
.pl_25 {
  padding-left: 25px;
}
.pl_30 {
  padding-left: 30px;
}
.pl_35 {
  padding-left: 35px;
}
.pl_40 {
  padding-left: 40px;
}

.containbox {
  padding-right: var(--containboxHPadding);
  padding-left: var(--containboxHPadding);
  margin-right: auto;
  margin-left: auto;
}
.content {
  /* max-width: var(--contentMaxWidth); */
  padding-right: var(--contentHPadding);
  padding-left: var(--contentHPadding);
  margin-right: auto;
  margin-left: auto;
}
.content1 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  max-width: var(--content1MaxWidth);
  margin-right: auto;
  margin-left: auto;
}
.content1 > * {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.max_width760 {
  max-width: 760px;
  margin: 40px auto;
}
.max_width760 h3 {
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
}

.t_up {
  text-transform: uppercase;
}
.text_center {
  text-align: center;
}
.text_center * {
  margin-right: auto;
  margin-left: auto;
}
.onboard_bg {
  background-color: var(--white);
  /* background-image: url(./../Images/onboard_bg.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover; */
}
.onflexlay {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bgOverlay);
}
.onbox {
  width: 95%;
  max-width: 494px;
  padding: 40px 32px 24px;
  background-color: var(--white);
  border-radius: 10px;
  margin: 24px 0;
}
.onbox2 {
  max-width: 988px;
  position: relative;
}
.onbox2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 1px;
  background-color: var(--borderColor);
  transform: translateX(-50%);
}
.onbox h2 + h5 {
  margin-top: 4px;
  margin-bottom: 16px;
}
.onbox .ikonlink {
  font-size: 11px;
}
.onbox .ikonlink svg {
  width: 10px;
  height: auto;
}
.onfooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.onfooter > div > button {
  width: 80px;
}
.onshadowbox {
  padding: 16px 16px 24px;
  background-color: var(--white);
  border: var(--lite) 1px solid;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}
.onheader1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}
.onheader1 h6 {
  font-weight: 600;
}
.onheader1 div > button {
  font-size: 10px;
  width: 56px;
  height: 26px;
  border-radius: 4px;
}
.onsection1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ff_semi,
.onsection1 h6 {
  font-weight: 600;
}

.skip {
  font-size: 12px;
  color: var(--greyO5);
}

.flex {
  display: flex;
}
.flex_wrap {
  flex-wrap: wrap;
}
.item_vcenter {
  align-items: center;
}
.item_vstart {
  align-items: flex-start;
}
.item_vend {
  align-items: flex-end;
}
.item_hcenter {
  justify-content: center;
}
.item_hend {
  justify-content: flex-end;
}
.item_space_btn {
  justify-content: space-between;
}
.item_space_ard {
  justify-content: space-around;
}
.item_space_evn {
  justify-content: space-evenly;
}
.gap_10 {
  gap: 10px;
}
.gap_16 {
  gap: 16px;
}
.gap_20 {
  gap: 20px;
}
.gap_24 {
  gap: 24px;
}
.gap_8 {
  gap: 8px;
}

.full {
  width: 100%;
}

.field_box {
  position: relative;
}
.label_box {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
label,
.label {
  font-weight: 500;
  font-size: 14px;
  line-height: var(--lableLineHeight);
  color: var(--lableColor);
}
.label2 {
  font-size: 11px;
  line-height: 20px;
  color: var(--lableColor);
}
.input {
  font-weight: 500;
  font-size: var(--inputFontSize);
  width: 100%;
  height: var(--inputHeight);
  line-height: var(--inputLineHeight);
  padding-right: var(--inputHPadding);
  padding-left: var(--inputHPadding);
  background-color: var(--inputBackground);
  color: var(--inputColor);
  border: var(--inputBorder);
  border-radius: var(--inputRadius);
  outline: 0;
  transition: all 0.2s ease;
}
.input::-webkit-input-placeholder {
  color: var(--inputPlaceholderColor);
}
.input:-moz-placeholder {
  color: var(--inputPlaceholderColor);
  opacity: 1;
}
.input::-moz-placeholder {
  color: var(--inputPlaceholderColor);
  opacity: 1;
}
.input:-ms-input-placeholder {
  color: var(--inputPlaceholderColor);
}
.input::-ms-input-placeholder {
  color: var(--inputPlaceholderColor);
}
.input::placeholder {
  color: var(--inputPlaceholderColor);
}
.dd_box {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  background-color: var(--white);
  padding: 16px;
  border: var(--inputBorder);
}
.relative {
  position: relative;
}
.add_remove_btns input {
  padding-right: 70px;
}
.accordion {
  width: 100%;
  padding: 18px 20px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accord_item {
  padding: 18px 20px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.accord_item + .accord_item {
  margin-top: 14px;
}
.accord_item .accordion {
  padding: 0;
}
.accordion_content {
  font-size: 16px;
  padding: 0px 20px 12px;
}

.boxon1 {
  position: relative;
  width: calc(50% - 4px);
  background-color: rgba(165, 97, 216, 0.05);
  padding: calc(var(--containboxHPadding) / 2);
  border-radius: var(--bgRadius);
}
.boxon2 {
  position: relative;
  width: calc(100% - 8px);
  background-color: rgba(165, 97, 216, 0.05);
  padding: calc(var(--containboxHPadding) / 2);
  border-radius: var(--bgRadius);
}
.bgGradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--bgRadius);
  overflow: hidden;
  z-index: -1;
}
.bgGradient::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
  width: 50.919%;
  padding-top: 50.919%;
  background-color: var(--primary);
  border-radius: 50%;
  filter: blur(213px);
}
.boxon2 .bgGradient::before {
  width: 25.45%;
  padding-top: 25.45%;
}
.footeron {
  padding: 20px calc((var(--containboxHPadding) / 2) + 20px);
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--lite);
  border-radius: 0 0 var(--bgRadius) var(--bgRadius);
}
.contenton1 {
  padding-right: 20px;
  padding-left: 20px;
}

.auth_box h1 {
  max-width: 435px;
}
.auth_box h5 {
  max-width: 550px;
}
.auth_form {
  max-width: 534px;
}
.auth_ikon {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: var(--greyO1p6);
}
.auth_ikon span {
  line-height: 0;
}
.error {
  font-size: 12px;
  color: var(--error);
}

.bglite {
  background-color: var(--lite);
  border-radius: 13px;
}
.auth_box .bglite {
  padding: 0 16px;
  margin-right: -20px;
  margin-left: -20px;
}

.side_title {
  padding: 24px 16px 15px;
  display: flex;
  align-items: center;
  border-bottom: var(--borderColor1) 1px solid;
}
.side_title h6 {
  margin-left: 20px;
}
.side_fitem {
  padding: 15px 16px 0;
}
.side_hpadd {
  padding-right: 16px;
  padding-left: 16px;
}
.another_box {
  padding: 14px 16px;
  border-bottom: var(--borderColor1) 1px solid;
}

.tabs {
  display: flex;
  align-items: center;
}
.tab,
button.tab,
a.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 25px;
  border: var(--borderColor1) 1px solid;
  background-color: var(--white);
  color: var(--lableColor);
}
.tab.active,
button.tab.active,
a.tab.active {
  border: var(--primary) 1px solid;
  background-color: var(--primary);
  color: var(--white);
}
.tab + .tab {
  margin-left: 16px;
}

.tbox {
  background-color: var(--white);
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
  border-radius: 10px;
}
.flexbox {
  display: flex;
  font-size: 11px;
  line-height: 20px;
  padding: 12px 8.5px;
  background-color: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05),
    0px 0px 1px rgba(12, 26, 75, 0.24);
}
.flexbox + .flexbox {
  margin-top: 12px;
}
.fbitem {
  flex: 1;
  padding: 0 11.5px;
}
.input3 {
  height: 20px;
  font-size: 11px;
  border: 0;
  border-bottom: var(--lite) 1px solid;
  background-color: var(--white);
  border-radius: 0;
  padding: 0;
}
.input_browse {
  border: 2px dashed var(--greylite);
  border-radius: 10px;
}
.input_browse.lg {
  padding: 60px 16px;
}
.upload1 {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafcff;
  border-radius: 10px;
}
.upload_img1 {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.upload_content1 {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.upload_content1 > img {
  margin-right: auto;
  margin-left: auto;
}
.upload_content1 p {
  font-size: 11px;
  margin-top: 8px;
  color: rgb(155 155 181 / 100%);
}
.hr_modal_right {
  width: calc(100% + 80px);
  margin-right: -40px;
  margin-left: -40px;
  border: 0;
  border-top: 1px solid rgba(40, 39, 41, 0.2);
}
.item_status {
  width: 224px;
  height: var(--inputHeight);
  padding: 2px;
  display: flex;
  align-items: center;
  background: #f6f7f9;
  border-radius: 28px;
}
.item_status button {
  font-size: 11px;
  color: var(--tableTh);
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 109px;
  height: calc(var(--inputHeight) - 4px);
  border: 0;
  outline: 0;
  box-shadow: none;
  background-color: transparent;
  border-radius: 28px;
}
.item_status button + button {
  margin-left: 2px;
}
.item_status button.active {
  background-color: var(--titary);
  color: var(--white);
}
.box2 {
  width: 100%;
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.box2_focus:focus {
  border: 1px solid var(--primary);
}
.w_356 {
  width: 356px;
}
.cat_grid {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}
.cat_col {
  width: 372px;
  padding: 8px;
}
.cat_box {
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  padding: 16px 16px 19px 10px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.cat_box.cat_box2 {
  padding: 16px 16px;
}
.cat_img {
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cat_img img {
  width: 100%;
  height: 128px;
  border-radius: 4px;
  object-fit: cover;
}
.cat_head {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
}
.cat_head1 {
  width: 100%;
}
.cat_name {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: var(--grey2);
  padding-right: 8px;
}
.cat_description {
  width: 100%;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 15px;
  color: rgba(40, 39, 41, 0.6);
  margin-top: 4px;
}
.badge {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px 8px;
  margin-left: 8px;
  background: var(--grey2);
  border-radius: 4px;
}
.badge.badge1 {
  background: var(--primary);
}
.badge.badge2 {
  background: rgba(40, 39, 41, 0.3);
}
.attach_branch {
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: var(--grey2);
}
.attach_branch + .attach_branch {
  margin-top: 7px;
}
.attach_ikon {
  margin-right: 8px;
}
.select_days {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  width: calc(100% + 8px);
}
.select_days > * {
  margin: 4px;
}
.color_box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
}
.color_box > div {
  margin: 4px;
}
.cat_head1_top {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.head_flex1 {
  display: flex;
}
.head_flex1 h6 {
  margin-right: 8px;
}
.head_flex1 .badge + .badge {
  margin-left: 10px;
}
.action_btns {
  display: flex;
  align-items: center;
}
.action_btns button + button {
  margin-left: 38px;
}
.cat_box2 hr {
  margin-top: 15px;
  margin-right: -16px;
  margin-left: -16px;
  width: calc(100% + 32px);
}
.modifier_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -11px;
  margin-right: -11px;
}
.modifier_item > div {
  padding: 11px 11px 0;
}

.radio_rows > div {
  width: 100%;
}
.radio_rows > div + div {
  margin-top: 16px;
}
.badge_lite1 {
  font-size: 10px;
  padding: 2px 10px;
  border-radius: 16px;
  line-height: 12px;
  color: #4aae8c;
  background-color: #deffee;
}
.badge_lite2 {
  font-size: 10px;
  padding: 2px 10px;
  border-radius: 16px;
  line-height: 12px;
  color: #f7936f;
  background-color: #ffede3;
}
.card_item1 {
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  box-shadow: 0px 3px 8px -1px rgb(50 50 71 / 5%);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.card_item1 img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.card_item1 .placeholder {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  width: 100%;
  height: 200px;
  display: flex;
}
.card_item1_box {
  padding: 16px;
}
.card_item1.active {
  border: 1px solid var(--primary);
}
.card_item1.card_item1_md {
  text-align: left;
}
.card_item1.card_item1_md picture {
  padding: 16px 14px 0px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
}
.card_item1.card_item1_md picture img {
  height: 124.09px;
  border-radius: 0 0 0 0;
}
.card_item1.card_item1_sm {
  text-align: left;
}
.card_item1.card_item1_sm picture {
  padding: 16px 14px 0px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
}
.card_item1.card_item1_sm picture img {
  height: 94px;
  border-radius: 0 0 0 0;
}
.card_item2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  box-shadow: 0px 3px 8px -1px rgb(50 50 71 / 5%);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.card_item2 picture {
  width: 120px;
  padding: 4px;
  line-height: 0;
  border: 1px solid var(--lite);
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 4px;
}
.card_item2 img {
  width: 100%;
  height: 112px;
  object-fit: contain;
  border-radius: 4px;
}
.card_item2 .placeholder {
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  display: flex;
}
.card_item2_box {
  width: calc(100% - 136px);
}
a.card_link:hover {
  text-decoration: none;
}
.card_item2:hover {
  background-color: var(--lite);
}
.card_item2:hover h6 {
  color: var(--black);
}
a.card_link:hover .alink {
  text-decoration: underline;
}
.card_item3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.card_item3 > div {
  width: 100%;
}
.card_item3 a {
  text-decoration: none;
}
.card_item3 picture {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-right: auto;
  margin-left: auto;
  line-height: 0;
  border: 1px solid var(--lite);
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 4px;
}
.card_item3 picture img {
  width: 100%;
  height: 62px;
  object-fit: contain;
  border-radius: 4px;
}
.card_item3.active {
  border: 1px solid var(--primary);
}
.card_item3.card_item3_md {
  height: 200px;
  padding-top: 0;
}
.card_item3:hover {
  background-color: var(--lite);
}
.card_item3:hover h6 {
  color: var(--black);
}
.card_item3:hover .hr1 {
  border-top: var(--grey1) 1px solid;
}
.card_item3 button[color='outline-titary']:hover {
  background-color: var(--titary);
  color: var(--white);
}
.branch_card1 {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  text-align: left;
  background: var(--white);
  border: 1px solid var(--lite);
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 10px;
}
.branch_card1 .picture {
  width: 101px;
  height: 198px;
  box-shadow: 0px 0px 0.57672px rgba(12, 26, 75, 0.24),
    0px 1.73016px 4.61376px -0.57672px rgba(50, 50, 71, 0.05);
  border-radius: 10px 0px 0px 10px;
  background-color: rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.branch_card1 .branch_content1 {
  width: calc(100% - 101px);
  padding: 20px;
}
.branch_content1 > p {
  font-size: 13px;
  color: #718096;
  line-height: 130%;
}
.branch_manager {
  display: flex;
  align-items: center;
}
.branch_manager picture {
  line-height: 0;
  width: 36px;
  margin-right: 8px;
}
.branch_manager picture img {
  width: 100%;
  height: 36px;
  object-fit: cover;
}
.branch_card1.active {
  border: 1px solid var(--primary);
}
.branch_card1.branch_card1_sm {
  height: 155px;
}
.branch_card1.branch_card1_sm .picture {
  height: 153px;
}
.spans {
  display: flex;
  align-items: center;
}
.spans span + span {
  margin-left: 8px;
}
.border_color {
  border: 0;
  border-bottom: var(--borderColor) 1px solid;
}

.kds_footer1 {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  justify-content: space-between;
  background: var(--white);
  border: 1px solid var(--lite);
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 10px;
}
.kds_footer1 > div:nth-of-type(1) {
  width: 66%;
  max-width: 356px;
}

.no_data1 {
  background-color: var(--greyO1p6);
  color: var(--grey2);
  padding: 12px 16px;
  text-align: center;
}

.rs-input-group-sm > .rs-input {
  padding: 12px 14px;
  font-size: 14px;
  font-weight: 500;
}
.rs-picker-toggle-wrapper {
  width: 100%;
}
.rs-input-group-sm.rs-input-group > .rs-input {
  height: 36px;
}
.rs-input-group-sm.rs-input-group > .rs-input-group-addon {
  height: 36px;
}
.rs-input-number-btn-group-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) {
  .forgot_link_row {
    display: flex;
    justify-content: space-between;
  }
}
