.wrapper {
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: #ffffff;
  border-radius: 12px;
  width: 100%;
  max-width: 100%;
}

.illustration {
  display: flex;
  align-items: flex-end;
  gap: 12px;
  height: 120px;
  margin-bottom: 24px;
}

.bar {
  width: 16px;
  background: #f0f0f0;
  border-radius: 8px;
  animation: pulse 2s infinite ease-in-out;
}

.bar:nth-child(1) {
  height: 40%;
  animation-delay: 0s;
}

.bar:nth-child(2) {
  height: 70%;
  animation-delay: 0.2s;
}

.bar:nth-child(3) {
  height: 50%;
  animation-delay: 0.4s;
}

.bar:nth-child(4) {
  height: 60%;
  animation-delay: 0.6s;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 8px 0;
}

.description {
  font-size: 14px;
  color: #718096;
  text-align: center;
  margin: 0;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scaleY(1);
  }
  50% {
    opacity: 0.7;
    transform: scaleY(0.95);
  }
  100% {
    opacity: 0.5;
    transform: scaleY(1);
  }
}

/* Update the existing resBx class */
.resBx {
  width: 100%;
  height: 100%;
  min-height: 300px;
  background: #ffffff;
  border-radius: 12px;
}

.noDataContainer {
  width: 100%;
  height: 100%;
  min-height: 300px;
}
