.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  padding: 40px 0;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.header > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.header h2 {
  font-weight: 600;
  line-height: normal;
}
.header h6 {
  font-weight: 400;
  line-height: normal;
}
.header > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.pageBx {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  background: #ECE6F7;
  border-radius: 10px;
  padding: 40px;
}
.pageBx > div {
  width: 100%;
  max-width: 982px;
  margin-right: auto;
  margin-left: auto;
}
.inputBx {
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: var(--white);
  border-radius: 10px;
}
.input {
  width: 100%;
  height: var(--inputHeight);
  font-size: 16px;
  color: var(--black);
}
.domainItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 20px;
}
.domainItem > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 80px;
}
.domainItem h6,
.inputBx h6 {
  font-weight: 500;
  color: var(--black);
}
.domainItem h6 b,
.inputBx h6 b {
  font-weight: 700;
}

.pageBx_1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  background: #ECE6F7;
  border-radius: 10px;
  padding: 40px;
}
.pageBx_1 > div {
  width: 100%;
  max-width: 982px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.pageBx_1 h4 {
  font-weight: 700;
  text-align: center;
}

.checkoutBx {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
}
.checkoutBx > div:nth-of-type(1) {
  padding-right: 18px;
  flex: 1;
}
.checkoutBx > div:nth-of-type(2) {
  width: 1px;
  background: var(--borderColor1);
}
.checkoutBx > div:nth-of-type(3) {
  width: 466px;
}
.card_1 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: var(--white);
  border-radius: 18px;
  padding: 18px;
}
.cardHead {
  display: flex;
  flex-direction: row;
  gap: 18px;
}
.cardHead > div:nth-of-type(2) {
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.cardHead h4 {
  font-weight: 700;
  color: var(--primary);
}
.cardFoot {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 18px;
}
.wwwBx {
  font-size: 20px;
  font-weight: 400;
  color: var(--white);
  background: var(--black);
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
}

.cardEleBx {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cardItem {
  width: 100%; 
  padding: 15px 12px; 
  background: var(--white); 
  border-radius: 8px; 
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
}