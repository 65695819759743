.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  padding: 40px 0;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.header > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.header h2 {
  font-weight: 600;
  line-height: normal;
}
.header h6 {
  font-weight: 400;
  line-height: normal;
}
.header > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.pageBx {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  background: #ECE6F7;
  border-radius: 10px;
  padding: 40px;
}
.pageBx > div {
  width: 100%;
  max-width: 982px;
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: var(--white);
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
}
.input {
  width: 100%;
  height: var(--inputHeight);
  font-size: 16px;
  color: var(--black);
}

.pageBx_1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  background: #ECE6F7;
  border-radius: 10px;
  padding: 40px;
}
.pageBx_1 > div {
  width: 100%;
  max-width: 982px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.pageBx_1 h4 {
  font-weight: 700;
  text-align: center;
}

.tableBx {
  background: var(--white);
  padding: 20px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}
.table_1 th {
  font-weight: 500;
  color: var(--white);
  background: var(--primary);
}
.table_1 thead th:first-child {
  border-radius: 8px 0 0 0;
}
.table_1 thead th:last-child {
  border-radius: 0 8px 0 0;
}
.table_1 th,
.table_1 td {
  font-size: 13px;
  padding: 10px 16px;
  text-align: left;
}
.table_1 td {
  font-weight: 400;
  color: var(--black);
  background: transparent;
}
.table_1 tr th:nth-of-type(1) {
  width: 20%;
}
.table_1 tr th:nth-of-type(2) {
  width: 45%;
}
.table_1 tr th:nth-of-type(3) {
  width: 35%;
}