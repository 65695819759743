@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root {
  --white: #ffffff;
  --danger: #f16063;

  --primary: #a561d8;
  --primary-rgb: 165, 97, 216;
  --primaryLite: #b789da;
  --primaryLoadingLite: #d9baf4;
  --secondary: #9747ff;
  --titary: #8d23dd;
  --black: #000000;
  --black-rgb: 0, 0, 0;
  --lite: #edf2f7;
  --lite1: #eeeeee;
  --error: #dd0000;
  --green: #66cb9f;
  --orange: #f6a000;
  --dark: #27272e;
  --dark1: #1e1e1e;
  --grey: #6e7072;
  --grey1: #d9d9d9;
  --grey2: #282729;
  --greylite: #718096;
  --greyO5: rgba(40, 39, 41, 0.5);
  --greyO1p6: rgba(40, 39, 41, 0.16);
  --bgOverlay: rgba(0, 0, 0, 0.31);
  --bgOverlay1: rgba(0, 0, 0, 0.7);
  --buttonBg: #8d23dd;
  --bgTableTh: #fafafb;
  --bgTableThHover: #eaeaeb;
  --tableTh: #8492a6;
  --tableTdColor: #425466;
  --asideNavColor: #16192c;
  --paginationColor: #7a7a9d;
  --paginationBg: #e4ecf7;
  --liteLabel: #a0aec0;

  --gap8: 8px;
  --gap10: 10px;
  --gap16: 16px;
  --gap20: 20px;
  --gap30: 30px;
  --gap32: 32px;
  --gap60: 60px;

  --borderColor: #e4ecf7;
  --borderColor1: #b2b3b5;
  --lableColor: rgb(20, 23, 31);
  --lableLineHeight: normal;

  --inputHeight: 40px;
  --inputHPadding: 24px;
  --inputSmHPadding: 9px;
  --inputFocusPadding: 8px;
  --inputBackground: transparent;
  --inputColor: var(--primary);
  --inputPlaceholderColor: rgba(155, 162, 176, 1);
  --inputBorder: 0;
  --inputRadius: 6px;
  --inputFontSize: 16px;
  --inputSmFontSize: 13px;
  --inputLineHeight: normal;
  --fieldErrorColor: #7a7a9d;

  --containboxHPadding: 8px;
  --contentHPadding: 8%;
  --asideBodyHPadding: 40px;
  --contentMaxWidth: 1188px;
  --content1MaxWidth: 900px;
  --boxPadding: 16px;

  --buttonHeight: 46px;
  --buttonHPadding: 16px;
  --buttonRadius: 8px;
  --buttonFontSize: 14px;
  --buttonLineHeight: 14px;

  --bgRadius: 16px;

  --headerHeight: 71px;
  --headerZIndex: 117;
  --modalZIndex: calc(var(--headerZIndex) + 100);

  --asideHPadding: 12px;
  --asideVPadding: 16px;
  --asideItemHPadding: 10px;
  --asideItemVPadding: 16px;
  --asideWidth: 250px;
  --asideSmartWidth: 70px;

  /* builder theme */
  --builder-theme-container-max-width: 100%;
  --builder-theme-horizontal-padding: 40px;
  /* /builder theme */
}

@media (max-width: 1199px) {
}

@media (max-width: 991px) {
  :root {
    --builder-theme-horizontal-padding: 16px;
  }
}

@media (max-width: 559px) {
}
