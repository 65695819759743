.article {
  width: 100%;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}
.headerLeft {
  display: flex;
  align-items: center;
}
.headerLeft picture {
  line-height: 0;
  width: 120px;
  border-radius: 10px;
  background-color: #d9d9d9;
  overflow: hidden;
  margin-right: 24px;
}
.headerLeft picture path {
  stroke-width: 5px;
}
.headerLeft h2 + h5 {
  margin-top: 4px;
}
.moreDetails {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
}
.moreDetails > div {
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.moreDetails span + span {
  margin-left: 8px;
}

.emptyBox {
  background-color: var(--grey1);
  border-radius: var(--inputRadius);
  padding: calc(var(--boxPadding) * 2);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.emptyBox > div:first-child {
  max-width: 340px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  min-height: 360px;
}
