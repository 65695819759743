.chart_box {
  min-width: 32%;
  height: auto;
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
  flex: 0 0 20%;
}
.center {
  justify-content: center;
  align-items: center;
}
.chart_box_1 {
  width: 49%;
  height: auto;
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  padding: 10px;
  display: flexbox;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}

.detail {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 0 15px 0 15px;
}
.txt {
  text-align: center;
}
.p {
  color: red;
  font-weight: 600;
}
.chart_box_2 {
  min-width: 32%;
  height: auto;
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  padding: 10px;
  display: flexbox;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
  flex: 0 0 20%;
}
.box-shadow-md {
  box-shadow: 1px 2px 9px #f4aab9;
  margin: 4em;
  padding: 1em;
}
.chart_title {
  font-weight: bold;
  color: black;
}
.tp_txt {
  color: #1d1921;
}
.top_fd {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.fs_18 {
  font-size: 18px;
}
.fs_14 {
  font-size: 14px;
}
.ov_top {
  position: absolute;
  z-index: 1;
}
.piechart {
  left: 10;
  margin-left: 10;
  align-self: self-end;
}
.w_40 {
  width: 100px;
}
.txt_div {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  width: 200px;
}
.num_per {
  color: #b789da;
  margin-top: -1px;
  font-size: 8;
}
