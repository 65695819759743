.box {
  padding: 0;
}
.buttonChOpt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  margin: 16px 0 0;
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  text-align: left;
  outline: 0;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
  cursor: pointer;
}
