.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.header > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.header h2 {
  font-weight: 600;
  line-height: normal;
}
.header h5 {
  font-weight: 500;
  line-height: normal;
}
.empty_state {
  width: 100%;
  max-width: 940px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding-top: 24px;
}
.empty_state h2 {
  font-weight: 600;
  line-height: normal;
}

.typeButton {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  background: var(--white);
  color: inherit;
  text-align: left;
  padding: 14.5px 16.5px;
  border: transparent 2px solid;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.typeButton > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.typeButton h6 {
  font-weight: 600;
}
.typeButton p {
  font-weight: 500;
  min-height: 40px;
}
.typeButton:hover,
.typeButton:active,
.typeButton.active {
  border: var(--primary) 2px solid;
}
.typeButton:hover svg,
.typeButton:active svg,
.typeButton.active svg {
  fill: var(--primary);
}

.search_grid {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 16px;
}
.search_grid > div {
  flex: 1 1 0;
}

.rowEqCol {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.rowEqCol > div {
  flex: 1;
}
.statBx {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 28px;
  background: var(--white);
  color: inherit;
  text-align: left;
  padding: 10px 13px;
  border: 0;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.statIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30.5px;
  height: 30.5px;
  padding: 6px;
  border-radius: 8px;
  background: rgba(255, 204, 145, 0.16);
  line-height: 0;
}
.statLabel {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: rgba(139, 141, 151, 1);
}
.statValue {
  font-size: 17px;
  font-weight: 500;
  line-height: normal;
  color: rgba(69, 70, 78, 1);
  margin-top: 7px;
}
.activeStat {
  background: var(--primary);
  color: var(--white);
}
.activeStat .statIcon {
  background: rgba(255, 255, 255, 0.16);
}
.activeStat .statLabel {
  color: var(--white);
}
.activeStat .statValue {
  color: var(--white);
}
.contentBx {
  background: var(--white);
  padding: 18px;
  border-radius: 10px;
}
.contentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 17px;
}
.contentHeader h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.searchBx {
  position: relative;
  background: rgba(139, 141, 151, 0.16);
  border-radius: 10px;
}
.searchBx input {
  border-radius: 6px;
  padding: 6.78px 6.78px 6.78px 30.56px;
}
.searchBx > article > div {
  border: 1px solid rgba(207, 211, 212, 1);
}
.searchButton {
  position: absolute;
  top: 50%;
  left: 6.78px;
  transform: translateY(-50%);
  width: 17px;
  height: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  padding: 0;
  height: auto;
  line-height: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
