.browse {
  padding: 0;
  width: 100%;
}
.bx {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  background: var(--white);
  border: transparent 1px solid;
  border-radius: 10px;
  padding: 24px;
}
.bx:focus-within {
  border: var(--primary) 1px solid;
}
.label {
  font-size: 12px;
  font-weight: 400;
  color: var(--lableColor);
  line-height: var(--lableLineHeight);
  padding: 16px 24px 0;
}
