.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: relative;
  height: 20px;
  width: 36px;
  background-color: rgba(var(--black-rgb), 0.67);
  border-radius: 20px;
}
.label input:checked ~ .checkmark {
  background-color: var(--titary);
}
.checkmark:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: var(--lite1);
  border-radius: 50%;
  transition: left 0.35s ease;
}
.label input:checked ~ .checkmark:after {
  background-color: var(--white);
  left: 18px;
}
