.page {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-top: 24px;
}

.listBx {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.listItem {
  width: calc(25% - 15px);
  max-width: 280px;
}
