.block {
  min-width: 24%;
  height: 280px;
  background: var(--white);
  border: 1px solid var(--lite);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
  flex: 0 0 20%;
  height: auto;
}
.head {
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 50px;
}
.row {
  display: flex;
  justify-content: space-between;
  border: 0.8px solid #edf2f7;
  align-items: center;
  border-radius: 5px;
  height: 50px;
}
.row_name {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.row_img {
  height: 30px;
  width: 30px;
  border-radius: 5px;
}
.stock {
  display: flex;
  /* width: 30%; */
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-right: 15px;
}
.stock_txt {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #27272e;
  margin-top: 4px;
}
.stock_t {
  font-weight: 600;
  font-size: 10px;
  color: #718096;
}
.serve_r {
  display: flex;
  border: 0.8px solid #edf2f7;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
}
.batch_box {
  width: 240px;
  margin-left: 10px;
  margin-top: 10px;
}
.batch_txt {
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  color: #8492a6;
}
.serving {
  width: 100%;
  flex-wrap: wrap;
}
.invntryBox {
  margin-left: 10px;
  margin-top: 15px;
  width: 230px;
}
.serving_div {
  flex-wrap: wrap;
}
.savebtn {
  margin-top: 20px;
  margin-left: 80%;
}
.mixtureBox {
  margin-left: 12px;
  margin-top: 15px;
  width: 180px;
  justify-content: center;
  align-items: center;
  height: auto;
  display: grid;
  text-align: center;
  cursor: pointer;
}
.mixImg {
  width: 80px;
  height: 80px;
  border-radius: 40px;
}
