.ticket {
  width: 100%;
  background-color: var(--white);
  border-radius: 8px;
  padding: 16px;
  border: var(--borderColor1) 1px solid;
}
.ticketHeader {
  display: flex;
  justify-content: space-between;
  height: 36px;
}
.thLeft {
  display: flex;
  align-items: center;
}
.thLeft > * + * {
  margin-left: 16px;
}
.badge {
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  color: var(--dark);
  background-color: var(--grey1);
  border-radius: 8px;
}
.badge > * + * {
  margin-left: 4px;
}
.time {
  font-size: 12px;
  color: var(--dark);
}
.expectText {
  font-weight: 500;
  font-size: 9px;
  color: var(--secondary);
}
.brand {
  font-weight: 500;
  font-size: 11px;
  color: var(--dark);
  text-align: center;
}
.locationPin {
  font-weight: 500;
  font-size: 11px;
  color: var(--dark);
  display: flex;
  align-items: center;
}
.locationPin > * + * {
  margin-left: 6px;
}
.status {
  width: 100%;
  height: 4px;
  display: flex;
  align-items: center;
  background-color: var(--grey1);
  border-radius: 4px;
  margin-top: 11px;
  overflow: hidden;
}
.status > * {
  height: 100%;
}
.green {
  background-color: var(--green);
  border-radius: 4px;
}
.orange {
  background-color: var(--orange);
  border-radius: 4px;
}
.red {
  background-color: var(--error);
  border-radius: 4px;
}
.ticketBody {
  height: 374px;
  overflow-y: auto;
}
.ticketTime {
  font-size: 12px;
  color: var(--tableTh);
}
.fitemName {
  font-weight: 500;
  font-size: 16px;
  color: var(--dark);
  padding-left: 27px;
}
.fitem {
  font-size: 16px;
  color: var(--grey);
  margin-top: 8px;
}

.ticketFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.ticketFooter > * {
  width: calc(50% - 8px);
}
.ticketFooter button {
  width: 100%;
}
